import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  SpecificationColumnType,
  SpecificationRowType,
  SpecificationType,
} from '_clients/types/types';
import { useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage } from 'redux/actions';
import PeriodDataContext from '../PeriodDataContext';
import DraggableTable from './DraggableTable';

interface Props {
  accountNumber: string;
}

const SpecificationTable = ({ accountNumber }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();
  const [columns, setColumns] = useState([] as SpecificationColumnType[]);
  const [rows, setRows] = useState([] as SpecificationRowType[]);
  const [specification, setSpecification] = useState({} as SpecificationType);

  const { clientId, period } = useContext(PeriodDataContext);

  useEffect(() => {
    const getSpecifications = async () => {
      try {
        const response = await sdk.getSpecifications({
          clientid: clientId,
          periodId: period.id,
          accountNumbers: [Number(accountNumber)],
        });
        const data = response.accounts[accountNumber];

        setColumns(data.columns);
        setRows(data.rows);
        setSpecification(data.specification);
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    };
    getSpecifications();
  }, [clientId, period, accountNumber]);

  async function initSpecification() {
    try {
      const response = await sdk.addSpecification({
        clientid: clientId,
        periodId: period.id,
        accountNumber: Number(accountNumber),
      });

      response && setSpecification(response);
      return response;
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }

  async function addRow() {
    const spec = specification.id ? specification : await initSpecification();
    if (spec?.id) {
      try {
        const newRow = await sdk.addSpecificationRow({
          clientid: clientId,
          specificationId: spec.id,
        });
        setRows((prevRows) => [...prevRows, { ...newRow, cells: [] }]);
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    }
  }

  function getNewCells(row, cellData) {
    return row.cells.find((c) => c.columnId === cellData.columnId)
      ? row.cells.map((cell) =>
          cellData.columnId === cell.columnId ? cellData : cell
        )
      : row.cells.concat([cellData]);
  }

  async function updateCell(value, rowId, columnId, rowIndex) {
    const specificationId = specification.id;
    if (specificationId) {
      const data = {
        clientid: clientId,
        specificationId,
        rowId,
        requestBody: {
          columnId,
          value,
        },
      };
      try {
        const cellExists = !!rows[rowIndex].cells.find(
          (c) => c.columnId === columnId
        )?.value;
        const cellData = cellExists
          ? await sdk.updateSpecificationCell(data)
          : await sdk.addSpecificationCell(data);

        setRows((prevRows) =>
          prevRows.map((row, i) =>
            rowIndex === i
              ? {
                  ...row,
                  cells: getNewCells(row, cellData),
                }
              : row
          )
        );
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    }
  }

  return (
    <DraggableTable
      columns={columns}
      rows={rows}
      onUpdateCell={(value, rowId, cellId, rowIndex) =>
        updateCell(value, rowId, cellId, rowIndex)
      }
      onMoveRow={(x) => console.log(x)}
      onMoveColumn={(x) => console.log(x)}
      onNewRow={() => addRow()}
    />
  );
};

export default SpecificationTable;
