/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountGroups } from '../models/AccountGroups';
import type { AccountingAccount } from '../models/AccountingAccount';
import type { AccountingPeriod } from '../models/AccountingPeriod';
import type { AnnualReport } from '../models/AnnualReport';
import type { AnnualReportChanges } from '../models/AnnualReportChanges';
import type { AnnualReportSignature } from '../models/AnnualReportSignature';
import type { BVSubmissionState } from '../models/BVSubmissionState';
import type { Checklist } from '../models/Checklist';
import type { ClientCompany } from '../models/ClientCompany';
import type { ClientInformation } from '../models/ClientInformation';
import type { ClientInformationUpdate } from '../models/ClientInformationUpdate';
import type { CognitoUser } from '../models/CognitoUser';
import type { DocumentConfiguration } from '../models/DocumentConfiguration';
import type { DocumentReference } from '../models/DocumentReference';
import type { FinancialReportChanges } from '../models/FinancialReportChanges';
import type { FinancialYear } from '../models/FinancialYear';
import type { GetOrganisation } from '../models/GetOrganisation';
import type { Municipalities } from '../models/Municipalities';
import type { Organisation } from '../models/Organisation';
import type { Parishes } from '../models/Parishes';
import type { PeriodLamps } from '../models/PeriodLamps';
import type { ProductPackage } from '../models/ProductPackage';
import type { PropertyTaxTable } from '../models/PropertyTaxTable';
import type { ReconciliationBalances } from '../models/ReconciliationBalances';
import type { SharesCompanyAnnualReport } from '../models/SharesCompanyAnnualReport';
import type { SIEVoucher } from '../models/SIEVoucher';
import type { SigningStatus } from '../models/SigningStatus';
import type { StockInventoryTable } from '../models/StockInventoryTable';
import type { TaxCalculationConfig } from '../models/TaxCalculationConfig';
import type { TaxFormField } from '../models/TaxFormField';
import type { TaxRates } from '../models/TaxRates';
import type { Voucher } from '../models/Voucher';
import type { VoucherReference } from '../models/VoucherReference';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {
  /**
   * Webhook for Stripe to send subscription events to
   * @returns any OK
   * @throws ApiError
   */
  public static stripeSubscriptionWebhook({
    requestBody,
  }: {
    requestBody: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/webhooks/stripe/subscription',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request. Stripe probably sent an event to this endpoint that we can not handle`,
        500: `Server error`,
      },
    });
  }

  /**
   * Webhook for Cling expense subscription events
   * @returns any OK
   * @throws ApiError
   */
  public static clingExpenseSubscriptionWebhook({
    requestBody,
    secret,
  }: {
    requestBody: {
      type: 'companyExpense.created';
      typeId: number;
      data: {
        id: number;
        status: 'new' | 'invoiced';
        type: string;
        object: string | null;
        objectId: string | null;
        createdAt: string;
      };
    };
    /**
     * Secret to access this webhook callback
     */
    secret?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/webhooks/cling/expense/',
      query: {
        secret: secret,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request. Cling might have sent a event that we can not process.`,
        401: `Unauthorized`,
        422: `Unprocessable Content`,
        500: `Server error`,
      },
    });
  }

  /**
   * Webhook for Cling document subscription events
   * @returns any OK
   * @throws ApiError
   */
  public static clingDocumentSubscriptionWebhook({
    requestBody,
    secret,
  }: {
    requestBody: {
      type: 'document.sent';
      typeId: string;
      data: {
        id: string;
        status: 'expired' | 'sent' | 'draft' | 'accepted' | 'denied' | 'voided';
        externalReferences: Array<{
          service?: string;
          type: string;
          typeId: string;
          createdAt: string;
        }>;
      };
    };
    /**
     * Secret to access this webhook callback
     */
    secret?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/webhooks/cling/document/',
      query: {
        secret: secret,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request. Cling might have sent a event that we can not process.`,
        401: `Unauthorized`,
        422: `Unprocessable Content`,
        500: `Server error`,
      },
    });
  }

  /**
   * Endpoint for bolagsverket events to be sent to
   * @returns any OK
   * @throws ApiError
   */
  public static bvSubmissionEvent({
    clientId,
    financialYear,
    userId,
    auth,
    requestBody,
  }: {
    /**
     * Client ID
     */
    clientId: string;
    /**
     * Financial year
     */
    financialYear: string;
    /**
     * User id
     */
    userId: string;
    /**
     * auth token that we sent to BV when creating the subscription
     */
    auth: string;
    requestBody: {
      data: {
        status:
          | 'arsred_inkommen'
          | 'arsred_registrerad'
          | 'arsred_avslutad_ej_registrerad'
          | 'arsred_forelaggande_skickat'
          | 'arsred_komplettering_inkommen';
      };
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/webhooks/bolagsverket/annualReport/',
      headers: {
        auth: auth,
      },
      query: {
        clientId: clientId,
        financialYear: financialYear,
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Token miss match`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get version of API
   * @returns any OK
   * @throws ApiError
   */
  public static getVersion(): CancelablePromise<{
    /**
     * semver number
     */
    version: string;
    /**
     * commit hash
     */
    commitHash: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/version/',
    });
  }

  /**
   * fetching the vacation debt for a set period
   * @returns any Ok
   * @throws ApiError
   */
  public static getVacationDebt({
    periodId,
    clientId,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the client
     */
    clientId: string;
  }): CancelablePromise<{
    id: number;
    voucherData: {
      VoucherNumber: number;
      VoucherSeries: string;
      VoucherYear: number;
    };
    clientId: string;
    userId: string;
    periodId: number;
    lastDay: string;
    employeesData: Array<{
      EmployeeId: string;
      EmployeeName: string;
      DaysEarned: number;
      WageEarned: number;
      VariableEarned: number;
      DebtEarned: number;
      DaysUnused: number;
      WageUnused: number;
      VariableUnused: number;
      DebtUnused: number;
      DaysSaved: number;
      WageSaved: number;
      DebtSaved: number;
      DebtAdvance: number;
      TotalDebtEmployee: number;
      TotalDebtEmployerContribution: number;
    }>;
    updatedAt: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vacation-debt/',
      query: {
        periodId: periodId,
        clientId: clientId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Upload user's profile picture
   * @returns any OK
   * @throws ApiError
   */
  public static uploadUserProfilePicture({
    requestBody,
  }: {
    /**
     * Profile picture
     */
    requestBody: any;
  }): CancelablePromise<{
    /**
     * Profile picture url
     */
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/me/picture/',
      body: requestBody,
      mediaType: 'image/jpeg',
      errors: {
        400: `Validation error or incorrent mimetype`,
        401: `Not authenticated`,
        500: `Error uploading the profile picture`,
      },
    });
  }

  /**
   * Get the Agoy invites for the requesting user
   * @returns any Invites for a user
   * @throws ApiError
   */
  public static getInvites(): CancelablePromise<
    Array<{
      id: string;
      email: string;
      organisation: {
        id: string;
        name: string | null;
        orgNumber: string | null;
      };
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/me/invites/',
      errors: {
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create a new invite to the users Agoy organisation, for the provided email
   * @returns any Returns the created invite
   * @throws ApiError
   */
  public static createInvite({
    requestBody,
  }: {
    requestBody: {
      /**
       * The invite will be created for this email
       */
      email: string;
    };
  }): CancelablePromise<{
    id: string;
    email: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/me/invites/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `User already a member or user already invited`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get the user input
   * @returns any Input for a user
   * @throws ApiError
   */
  public static getUserInput({
    clientId,
    accountingYear,
    financialYearId,
  }: {
    /**
     * UUID of the client
     */
    clientId: string;
    accountingYear: string;
    financialYearId: number;
  }): CancelablePromise<{
    inputData: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/input/',
      query: {
        clientId: clientId,
        accountingYear: accountingYear,
        financialYearId: financialYearId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Not authorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * View the iXBRL of the annual report
   * @returns string OK
   * @throws ApiError
   */
  public static renderAnnualReport({
    requestBody,
  }: {
    requestBody: {
      clientId: string;
      financialYear: string;
      annualReport: SharesCompanyAnnualReport;
      documentConfiguration: DocumentConfiguration;
    };
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/test/renderAnnualReport',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Send external comment by email
   * @returns any OK
   * @throws ApiError
   */
  public static sendExternalCommentsByEmail({
    requestBody,
  }: {
    requestBody: {
      mailTo: Array<string>;
      mailCopy: Array<string>;
      mailSubject: string;
      mailMessage: string;
    };
  }): CancelablePromise<{
    message: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tax/send-email/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid ID`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get all Swedish parishes registered by Skatteverket
   * @returns Parishes List of parishes
   * @throws ApiError
   */
  public static getParishes(): CancelablePromise<Parishes> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tax/rates/parishes/',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get all Swedish municipalities registered by Skatteverket
   * @returns Municipalities List of municipalities
   * @throws ApiError
   */
  public static getMunicipalities(): CancelablePromise<Municipalities> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tax/rates/municipalities/',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get tax rates
   * @returns TaxRates Tax rates
   * @throws ApiError
   */
  public static getTaxRates({
    municipality,
    parish,
    year,
  }: {
    /**
     * Filter string for municipality (case insensitive contains)
     */
    municipality?: string;
    /**
     * Filter string for parish (case insensitive contains)
     */
    parish?: string;
    /**
     * Filter string for year (contains)
     */
    year?: string;
  }): CancelablePromise<TaxRates> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tax/rates/',
      query: {
        municipality: municipality,
        parish: parish,
        year: year,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * get all forms for a selected fiancial year.
   * @returns any List of forms
   * @throws ApiError
   */
  public static getFormsForFinancialYear({
    financialYear,
    clientType = 'C',
  }: {
    /**
     * The Financial year you wish to get the forms for
     */
    financialYear: string;
    /**
     * the type of client you wish find the forms for. C for company, P for person etc.
     */
    clientType?: string;
  }): CancelablePromise<
    Array<{
      id: string;
      domain: string;
      type: string;
      typeId?: string;
      category: 'main' | 'sub' | 'helper' | 'other' | 'coverLetter';
      name: string;
      title?: string | null;
      subtitle?: string | null;
      readOnly: boolean;
      subFormsIds?: Array<string>;
      thumbnailUrl?: string;
      externalDocuments: Record<
        string,
        {
          documentType: string;
          multiple: boolean;
          financialYear?: number;
          relationType?: 'ownership' | 'sibling' | 'ownedByOwner';
        }
      >;
      version: number;
      relatedClient: Record<
        string,
        {
          information: Array<'clientInformation' | 'ownership'>;
        }
      > | null;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tax/forms/',
      query: {
        financialYear: financialYear,
        clientType: clientType,
      },
      errors: {
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * print SKV form
   * @returns any OK
   * @throws ApiError
   */
  public static printSkvForm({
    clientId,
    financialYear,
    formId,
    requestBody,
  }: {
    /**
     * id of the client
     */
    clientId: string;
    financialYear: string;
    formId: string;
    /**
     * The document names and values for the form to be printed
     */
    requestBody: {
      documentName: string;
      taxFormValues?: any;
      printingParts: Array<'taxForm' | 'additionalPages' | 'coverLetter'>;
      documentId?: string;
    };
  }): CancelablePromise<{
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tax/forms/',
      query: {
        clientId: clientId,
        financialYear: financialYear,
        formId: formId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get all relevant system messages
   * @returns any System messages
   * @throws ApiError
   */
  public static getSystemMessages(): CancelablePromise<
    Array<{
      id: number;
      title: string;
      body: string;
      type: 'info' | 'warning' | 'error';
      showInApp: 'agoy' | 'fortnox' | 'all';
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/system-messages/',
      errors: {
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Adds a Stock Transaction to an exisiting client Relation
   * @returns any Created
   * @throws ApiError
   */
  public static addStockTransaction({
    requestBody,
  }: {
    requestBody: {
      clientRelationId: number;
      shareChange: number;
      transactionDate: string;
      valuePerShare: number;
      comment?: string;
    };
  }): CancelablePromise<{
    id: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/stock-transactions/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Not authenticated`,
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * fetching StockInventoryTable for the client.
   * @returns StockInventoryTable Ok
   * @throws ApiError
   */
  public static getStockInventoryTable({
    periodId,
    clientCompanyId,
    account,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the clientCompany
     */
    clientCompanyId: string;
    /**
     * the account number.
     */
    account: number;
  }): CancelablePromise<StockInventoryTable> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/stock-inventory-table/',
      query: {
        periodId: periodId,
        clientCompanyId: clientCompanyId,
        account: account,
      },
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * storing StockInventoryTable for the client.
   * @returns any Created
   * @throws ApiError
   */
  public static addStockInventoryTable({
    periodId,
    clientCompanyId,
    account,
    requestBody,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the clientCompany
     */
    clientCompanyId: string;
    /**
     * the account number.
     */
    account: number;
    requestBody: StockInventoryTable;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/stock-inventory-table/',
      query: {
        periodId: periodId,
        clientCompanyId: clientCompanyId,
        account: account,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * update the PropertyTaxTable for the client.
   * @returns any Updated
   * @throws ApiError
   */
  public static updateStockInventoryTable({
    periodId,
    clientCompanyId,
    account,
    requestBody,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the clientCompany
     */
    clientCompanyId: string;
    /**
     * the account number.
     */
    account: number;
    requestBody: StockInventoryTable;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/stock-inventory-table/',
      query: {
        periodId: periodId,
        clientCompanyId: clientCompanyId,
        account: account,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * removing StockInventoryTable for the client.
   * @returns void
   * @throws ApiError
   */
  public static deleteStockInventoryTable({
    periodId,
    clientCompanyId,
    account,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the clientCompany
     */
    clientCompanyId: string;
    /**
     * the account number.
     */
    account: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/stock-inventory-table/',
      query: {
        periodId: periodId,
        clientCompanyId: clientCompanyId,
        account: account,
      },
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get Skatteverket tokens create integration key
   * @returns any Ok
   * @throws ApiError
   */
  public static getSkatteverketAuth({
    authenticationCode,
  }: {
    /**
     * code for getting tokens from Skatteverket
     */
    authenticationCode?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/skatteverket/auth/',
      query: {
        authenticationCode: authenticationCode,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Redirect skatteverket answer
   * @returns void
   * @throws ApiError
   */
  public static getSkatteverket({
    code,
    state,
    error,
  }: {
    /**
     * code for getting tokens from Skatteverket
     */
    code?: string;
    /**
     * state number
     */
    state?: string;
    /**
     * Authentication canceled
     */
    error?: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/skatteverket/',
      query: {
        code: code,
        state: state,
        error: error,
      },
      errors: {
        302: `Redirect`,
        400: `Authentication canceled`,
      },
    });
  }

  /**
   * Redirect fortnox integration oauth
   * @returns void
   * @throws ApiError
   */
  public static getFortnoxIntegrationRedirect({
    code,
    state,
  }: {
    /**
     * code for getting tokens from Skatteverket
     */
    code?: string;
    /**
     * state number
     */
    state?: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/redirects/fortnox-oauth/',
      query: {
        code: code,
        state: state,
      },
      errors: {
        302: `Redirect`,
        400: `Authentication canceled`,
      },
    });
  }

  /**
   * fetching PropertyTaxTable for the client.
   * @returns PropertyTaxTable Ok
   * @throws ApiError
   */
  public static getPropertyTaxTable({
    periodId,
    clientId,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the client, company or person.
     */
    clientId: string;
  }): CancelablePromise<PropertyTaxTable> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/property-tax-table/',
      query: {
        periodId: periodId,
        clientId: clientId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * storing PropertyTaxTable for the client.
   * @returns any Created
   * @throws ApiError
   */
  public static addPropertyTaxTable({
    periodId,
    clientId,
    requestBody,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the clientCompany or person
     */
    clientId: string;
    requestBody: PropertyTaxTable;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/property-tax-table/',
      query: {
        periodId: periodId,
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * update the PropertyTaxTable for the client.
   * @returns any Updated
   * @throws ApiError
   */
  public static updatePropertyTaxTable({
    periodId,
    clientId,
    requestBody,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the clientCompany or person
     */
    clientId: string;
    requestBody: PropertyTaxTable;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/property-tax-table/',
      query: {
        periodId: periodId,
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * storing PropertyTaxTable for the client.
   * @returns void
   * @throws ApiError
   */
  public static deletePropertyTaxTable({
    periodId,
    clientId,
  }: {
    /**
     * id of the period
     */
    periodId: number;
    /**
     * id of the clientCompany or person
     */
    clientId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/property-tax-table/',
      query: {
        periodId: periodId,
        clientId: clientId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get program statuses of clients
   * @returns any OK
   * @throws ApiError
   */
  public static getProgramStatuses({
    clientIds,
    program,
    userid,
  }: {
    clientIds: Array<string>;
    program: string;
    userid?: string;
  }): CancelablePromise<
    Record<
      string,
      Array<{
        program: string;
        status:
          | 'NOT_STARTED'
          | 'STARTED'
          | 'AW_COMPL'
          | 'AW_REVIEW'
          | 'DONE'
          | 'LOCKED';
        previousStatus:
          | 'NOT_STARTED'
          | 'STARTED'
          | 'AW_COMPL'
          | 'AW_REVIEW'
          | 'DONE'
          | 'LOCKED'
          | null;
        reason: string | null;
        givenName: string;
        familyName: string;
        period?: string | null;
        periodId?: number | null;
        financialYear?: string | null;
        financialYearId?: number | null;
        createdAt: string;
      }>
    >
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/program-statuses/',
      query: {
        clientIds: clientIds,
        program: program,
        userid: userid,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create a status of a program
   * @returns void
   * @throws ApiError
   */
  public static postProgramStatuses({
    requestBody,
  }: {
    requestBody: {
      clientId: string;
      program: string;
      status:
        | 'NOT_STARTED'
        | 'STARTED'
        | 'AW_COMPL'
        | 'AW_REVIEW'
        | 'DONE'
        | 'LOCKED';
      reason?: string;
      period?: string;
      periodId?: number;
      financialYear?: string;
      financialYearId?: number;
      /**
       * If other changes in statuses are required to set the status, then send 'true' will apply those.
       */
      approved?: boolean;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/program-statuses/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Print a report
   * @returns any OK
   * @throws ApiError
   */
  public static printReport({
    clientId,
    year,
    docType,
    name,
    requestBody,
  }: {
    clientId: string;
    year: string;
    docType: string;
    name: string;
    requestBody: {
      parts: Array<string>;
      printState: any;
      header: string;
      footer: string;
      includeIndex?: boolean;
      attachments?: Array<string>;
    };
  }): CancelablePromise<{
    res: {
      url: string;
      pageNumbers: Record<string, number>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/print/',
      query: {
        clientId: clientId,
        year: year,
        docType: docType,
        name: name,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Not authorized`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Preview changes to an organisations subscription
   * @returns any OK
   * @throws ApiError
   */
  public static previewSubscriptionChanges({
    bigPackageId,
    bigPackageQuantity,
    smallPackageId,
    smallPackageQuantity,
  }: {
    /**
     * DB id of the big package
     */
    bigPackageId: number;
    /**
     * Number of seats for the big package
     */
    bigPackageQuantity: number;
    /**
     * DB id of the small package
     */
    smallPackageId: number;
    /**
     * Number of seats for the small package
     */
    smallPackageQuantity: number;
  }): CancelablePromise<{
    /**
     * The upcoming invoice that will accomodate for the changes made to the subscription
     */
    nextInvoiceTotal?: number;
    /**
     * The start of the next normal billing cycle (Unix epoch seconds)
     */
    nextBillingCycle: number;
    /**
     * The amount that the customer needs to pay on the next billing cycle
     */
    nextBillingCycleTotal: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/subscription/preview-changes/',
      query: {
        bigPackageId: bigPackageId,
        bigPackageQuantity: bigPackageQuantity,
        smallPackageId: smallPackageId,
        smallPackageQuantity: smallPackageQuantity,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign a package to a user
   * @returns any OK
   * @throws ApiError
   */
  public static assignPackageToUser({
    requestBody,
  }: {
    requestBody: {
      package: 'big' | 'small';
      userId: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/payment/subscription/packages/users/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * List the packages that the organisation posesses together with assigned users. Can only be requested by an org admin
   * @returns any OK
   * @throws ApiError
   */
  public static listOrganisationPackages(): CancelablePromise<{
    big: ProductPackage;
    small: ProductPackage;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/subscription/packages/',
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update the subscription packages
   * @returns void
   * @throws ApiError
   */
  public static udpateOrganisationPackages({
    requestBody,
  }: {
    requestBody: {
      /**
       * DB id of the big package
       */
      bigPackageId: number;
      /**
       * Number of seats for the big package
       */
      bigPackageQuantity: number;
      /**
       * DB id of the small package
       */
      smallPackageId: number;
      /**
       * Number of seats for the small package
       */
      smallPackageQuantity: number;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/subscription/packages/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create a Stripe customer portal. Only used for subscriptions, not PAYG. Lets the organisation select which packages it wants to subscribe to
   * @returns any OK
   * @throws ApiError
   */
  public static createCustomerPortal({
    requestBody,
  }: {
    requestBody: {
      returnUrl: string;
    };
  }): CancelablePromise<{
    /**
     * Stripe portal URL
     */
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/stripe/createCustomerPortal/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid org`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create a Stripe checkout session. Only used for subscriptions, not PAYG. Supply desired packages that the organisation should subscribe to. The system will update any existing subsciption.
   * @returns any OK
   * @throws ApiError
   */
  public static createCheckoutSession({
    requestBody,
  }: {
    requestBody: {
      successUrl: string;
      cancelUrl: string;
      packages: Array<{
        packageId: number;
        quantity: number;
      }>;
    };
  }): CancelablePromise<{
    /**
     * Stripe checkout URL
     */
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/stripe/createCheckoutSession/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid org`,
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Check if a specific pay-as-you-go program has already been paid for
   * @returns any OK
   * @throws ApiError
   */
  public static paygCheck({
    k2K3,
    program,
    clientId,
    financialYear,
    financialYearId,
  }: {
    k2K3: 'k1' | 'k2' | 'k3';
    /**
     * The program to check for
     */
    program:
      | 'FIN_STATEMENT'
      | 'FIN_STATEMENT_REPORT'
      | 'AN_REPORT'
      | 'RECONCILIATION';
    clientId: string;
    financialYear: string;
    financialYearId: number;
  }): CancelablePromise<{
    /**
     * true if it is paid for
     */
    paid: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/payg/program/',
      query: {
        k2k3: k2K3,
        program: program,
        clientId: clientId,
        financialYear: financialYear,
        financialYearId: financialYearId,
      },
      errors: {
        500: `Server error`,
      },
    });
  }

  /**
   * Confirm that a charge will be made based on supplied arguments
   * @returns any OK
   * @throws ApiError
   */
  public static paygConfirmCharge({
    requestBody,
  }: {
    requestBody: {
      k2k3: 'k1' | 'k2' | 'k3';
      program:
        | 'FIN_STATEMENT'
        | 'FIN_STATEMENT_REPORT'
        | 'AN_REPORT'
        | 'RECONCILIATION';
      clientId: string;
      financialYear: string;
      financialYearId: number;
    };
  }): CancelablePromise<{
    /**
     * true if it is paid for
     */
    paid: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/payg/program/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns the PAYG usage for the current billing cycle of the requesting user's organisation
   * @returns any OK
   * @throws ApiError
   */
  public static getPaygUsage(): CancelablePromise<{
    /**
     * Number of K2 reports purchased within the current financial year
     */
    k2Quantity: number;
    /**
     * Number of K3 reports purchased within the current financial year
     */
    k3Quantity: number;
    /**
     * Total cost of all the reports for the billing cycle. Taxes not included
     */
    total: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/payg/',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns the license of the requesting user
   * @returns any OK
   * @throws ApiError
   */
  public static getUserLicense(): CancelablePromise<{
    /**
     * Active license for the requesting user
     */
    license: 'none' | 'small' | 'big';
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/license/',
      errors: {
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Send a tick to Fortnox that the user downloaded a new tax form
   * @returns any OK
   * @throws ApiError
   */
  public static sendTaxReturnTickToFortnox({
    requestBody,
  }: {
    requestBody: Array<{
      clientId: string;
      finYearEnd: string;
      taxReturnRules: Array<
        | 'INK1'
        | 'INK2'
        | 'INK3'
        | 'INK4'
        | 'K2'
        | 'K4'
        | 'K5'
        | 'K6'
        | 'K7'
        | 'K8'
        | 'K9'
        | 'K10'
        | 'K11'
        | 'K12'
        | 'K13'
        | 'K15A'
        | 'K15B'
        | 'N3A'
        | 'NE'
        | 'T2'
        | 'OU'
        | 'NEA'
        | 'N3B'
        | 'N4'
        | 'N7'
        | 'N9'
      >;
    }>;
  }): CancelablePromise<{
    /**
     * true if it is done
     */
    success: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/invoicing/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update organisation settings
   * @returns void
   * @throws ApiError
   */
  public static updateOrganisationSettings({
    requestBody,
  }: {
    /**
     * Organisation settings
     */
    requestBody: {
      reconciliation?: {
        periodDoneAccountActions?: 'no_action' | 'mark_all_accounts';
      };
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organisation/settings/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Not authorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Check if an organisation has paid access to Agoy
   * @returns any OK
   * @throws ApiError
   */
  public static getOrganisationPaidAccess(): CancelablePromise<{
    hasAccess: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisation/paid-access/',
      errors: {
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get all the members of an organisation (member = Agoy user belonging to an organisation)
   * @returns any OK
   * @throws ApiError
   */
  public static getOrganisationMembers(): CancelablePromise<
    Array<
      | {
          userId: string;
          organisationId?: string;
          roles?: Array<string>;
          givenName?: string;
          familyName?: string;
          fullName?: string;
          origin: 'fortnox';
          email?: string;
        }
      | {
          userId: string;
          organisationId?: string;
          roles?: Array<string>;
          givenName?: string;
          familyName?: string;
          phoneNumber?: string;
          email?: string;
          origin: 'agoy';
        }
    >
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisation/members/',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update the organisation's logo
   * @returns any Returns the url to the updates organisation logo
   * @throws ApiError
   */
  public static updateOrganisationLogo({
    requestBody,
  }: {
    /**
     * New logo image as string
     */
    requestBody: any;
  }): CancelablePromise<{
    logo: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisation/logo/',
      body: requestBody,
      mediaType: 'image/jpeg',
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get invites for an organisation
   * @returns any Organisation's invites
   * @throws ApiError
   */
  public static getOrganisationInvites(): CancelablePromise<
    Array<{
      id: string;
      email: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisation/invites/',
      errors: {
        400: `Missing user attributes`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Fetch checklists belonging to organisation.
   * @returns Checklist OK
   * @throws ApiError
   */
  public static getChecklistsForOrg({
    showinactive = false,
  }: {
    /**
     * Optional parameter to fetch inactive checklists
     */
    showinactive?: boolean;
  }): CancelablePromise<Array<Checklist>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisation/checklists/',
      query: {
        showinactive: showinactive,
      },
      errors: {
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create checklist belonging to organisation.
   * @returns any CheckList created
   * @throws ApiError
   */
  public static createCheckListTemplate({
    requestBody,
  }: {
    /**
     * these are the checklist attributes required for creation.
     */
    requestBody: {
      name: string;
      description: string;
      type: 'empty' | 'tax' | 'annual-report' | 'tax-declaration';
      state: 'active' | 'inactive';
      program:
        | 'reconciliation_fin_statement_company'
        | 'annual_report_company'
        | 'tax_declaration_company'
        | 'tax_declaration_person';
      organisationId?: string;
      groups: Array<{
        label: string;
        periodicity:
          | 'every_period'
          | 'even_month'
          | 'odd_month'
          | 'every_quarter'
          | 'half_year'
          | 'last_period'
          | 'first_period';
        order?: number;
        fromAccount?: string;
        toAccount?: string;
        questions: Array<{
          label: string;
          periodicity:
            | 'every_period'
            | 'even_month'
            | 'odd_month'
            | 'every_quarter'
            | 'half_year'
            | 'last_period'
            | 'first_period';
          order?: number;
          fromAccount?: string;
          toAccount?: string;
        }>;
      }>;
      clientIds: Array<string>;
    };
  }): CancelablePromise<{
    id: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisation/checklists/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update checklists belonging to organisation.
   * @returns void
   * @throws ApiError
   */
  public static hideCheckListTemplate({
    requestBody,
  }: {
    /**
     * Client data to update
     */
    requestBody: {
      templateId: number;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organisation/checklists/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Checklist not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update checklist state belonging to organisation.
   * @returns void
   * @throws ApiError
   */
  public static setCheckListTemplateState({
    requestBody,
  }: {
    /**
     * State to update
     */
    requestBody: {
      state?: 'active' | 'inactive';
      includeInPrint?: boolean;
      templateId: number;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/organisation/checklists/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Checklist not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Fetch user's organisation information
   * @returns GetOrganisation OK
   * @throws ApiError
   */
  public static getOrganisation(): CancelablePromise<GetOrganisation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organisation/',
      errors: {
        400: `Organisation does not exist`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create an organisation
   * @returns Organisation Organisation created
   * @throws ApiError
   */
  public static createOrganisation({
    requestBody,
  }: {
    /**
     * Organisation information
     */
    requestBody: {
      orgNumber: string;
      name: string;
      address: string;
      city: string;
      country: string;
      postalCode: string;
    };
  }): CancelablePromise<Organisation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisation/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update an organisation
   * @returns any Organisation updated
   * @throws ApiError
   */
  public static updateOrganisation({
    requestBody,
  }: {
    /**
     * Organisation information
     */
    requestBody: {
      name: string;
      address: string;
      city: string;
      country: string;
      postalCode: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organisation/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        409: `OrgNumber should be unique`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get health of API, crucial for the load balancer
   * @returns any OK
   * @throws ApiError
   */
  public static getHealth(): CancelablePromise<{
    /**
     * API status
     */
    status: string;
    /**
     * API status message
     */
    message: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health/',
      errors: {
        500: `Server error (DB connection failed)`,
      },
    });
  }

  /**
   * Endpoint for FN Version of agoy to set up the integration from and fetch the jwt
   * @returns any OK
   * @throws ApiError
   */
  public static agoyFortnox({
    code,
    mac,
    bureauTenantId,
    tenantId,
  }: {
    /**
     * Supplied by FN, required to verify user
     */
    code: string;
    /**
     * Supplied by FN, required to verify user
     */
    mac: string;
    /**
     * The bureau's tenant id in FN
     */
    bureauTenantId?: string;
    /**
     * The tenant id in FN
     */
    tenantId?: string;
  }): CancelablePromise<{
    agoyJwt: string;
    /**
     * Type of client that clientId references, P = ClientPerson, C = ClientCompany
     */
    clientType?: 'P' | 'C';
    clientId?: Array<string>;
    /**
     * Org/person numbers of clients in Fortnox that are skipped in the sync
     */
    skippedClients?: Array<string>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/fn/fortnox-jwt',
      query: {
        bureauTenantId: bureauTenantId,
        tenantId: tenantId,
        code: code,
        mac: mac,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Endpoint for FN Version of agoy to set up the integration from and fetch the jwt
   * @returns void
   * @throws ApiError
   */
  public static disconnectFortnox({
    organisationId,
  }: {
    /**
     * The organisation to disconnect
     */
    organisationId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/fn/disconnect',
      query: {
        organisationId: organisationId,
      },
      errors: {
        400: `Invalid params`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Send financial report by email
   * @returns any OK
   * @throws ApiError
   */
  public static sendFinancialReportByEmail({
    requestBody,
  }: {
    requestBody: {
      mailTo: Array<string>;
      mailCopy: Array<string>;
      mailSubject: string;
      mailMessage: string;
      report: string;
      reportName: string;
    };
  }): CancelablePromise<{
    message: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/financial-report/send-email/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid ID`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get clients
   * @returns ClientCompany OK
   * @throws ApiError
   */
  public static getClients({
    clientId,
    operation,
    nameOrgnumberFilter,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientId?: string;
    /**
     * name of the operation
     */
    operation?: 'getNonConnectedClients';
    /**
     * search parameter for the filter. Filter over company name or organisation number.
     */
    nameOrgnumberFilter?: string;
  }): CancelablePromise<Array<ClientCompany>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/',
      query: {
        clientId: clientId,
        operation: operation,
        nameOrgnumberFilter: nameOrgnumberFilter,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add client
   * @returns ClientCompany OK
   * @throws ApiError
   */
  public static postClients({
    requestBody,
  }: {
    requestBody: {
      address?: string;
      closingMonth: number;
      closingPeriod: string;
      managerId?: string;
      name: string;
      orgNumber?: string;
      contact?: string;
      number?: string;
      email?: string;
      type?:
        | 'shares'
        | 'limited'
        | 'limited_partnership'
        | 'individual'
        | 'economic_association'
        | 'non_profit_association'
        | 'foundation'
        | 'other';
      vatPeriod: 'month' | 'quarter' | 'year' | 'novat';
      startDate?: string;
      visible?: boolean;
      fromCS?: boolean;
      stockTotal?: {
        shareTotal: number;
        type: 'new_registration' | 'purchase' | 'new_emission' | 'other';
        date: string;
        comment?: string | null;
      };
    };
  }): CancelablePromise<ClientCompany> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't create client`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add clientPerson
   * @returns any Created
   * @throws ApiError
   */
  public static postClientPerson({
    requestBody,
  }: {
    requestBody: {
      taxAddress: {
        zipCode: string;
        street: string;
        city: string;
        additionalAddressField1?: string;
        additionalAddressField2?: string;
        country: string;
      };
      postAddress?: {
        zipCode: string;
        street: string;
        city: string;
        additionalAddressField1?: string;
        additionalAddressField2?: string;
        country: string;
      } | null;
      clientPerson: {
        contactEmail: string;
        logo: string;
        managerId: string | null;
        firstName: string;
        lastName: string;
        contactPhoneNumber?: string;
        personNumber: string;
        organisationId?: string;
      };
    };
  }): CancelablePromise<{
    id: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/client-persons/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        404: `Organisation not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get clientPerson
   * @returns any List of client persons
   * @throws ApiError
   */
  public static getClientPersons(): CancelablePromise<
    Array<{
      id: string;
      firstName: string;
      lastName: string;
      personNumber: string;
      managerId: string | null;
      logo: string | null;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client-persons/',
      errors: {
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get activity filtered and paginated log events
   * @returns any OK
   * @throws ApiError
   */
  public static getActivityLogEvents({
    offset,
    limit,
    fromDate,
    toDate,
    userId,
    program,
    section,
    clientId,
  }: {
    /**
     * pagination offset
     */
    offset?: number;
    /**
     * pagination limit
     */
    limit?: number;
    /**
     * format YYYY-MM-DD
     */
    fromDate?: string;
    /**
     * format YYYY-MM-DD
     */
    toDate?: string;
    /**
     * user that triggered the event
     */
    userId?: string;
    /**
     * event's program id
     */
    program?: string;
    /**
     * event's section id
     */
    section?: string;
    clientId?: string;
  }): CancelablePromise<{
    totalResults: number;
    totalPages: number;
    currentPage: number;
    results: Array<{
      id: number;
      date: string;
      userName: string;
      program: string;
      section: string;
      description: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/activity-log-events/',
      query: {
        offset: offset,
        limit: limit,
        fromDate: fromDate,
        toDate: toDate,
        userId: userId,
        program: program,
        section: section,
        clientId: clientId,
      },
      errors: {
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Persist activity log events
   * @returns void
   * @throws ApiError
   */
  public static postActivityLogEvents({
    requestBody,
  }: {
    requestBody: {
      /**
       * example, 202011031013020
       */
      date: string | null;
      userId: string | null;
      clientId: string | null;
      program: string;
      section: string;
      resource: string;
      operation: string;
      arguments: Array<string>;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/activity-log-events/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Wrong event context`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get all the information of a selected user (member = Agoy user belonging to an organisation)
   * @returns any OK
   * @throws ApiError
   */
  public static getUserInformation({
    userId,
  }: {
    /**
     * UUID of the user
     */
    userId: string;
  }): CancelablePromise<
    | {
        userId: string;
        organisationId?: string;
        roles?: Array<string>;
        givenName?: string;
        familyName?: string;
        fullName?: string;
        origin: 'fortnox';
        email?: string;
      }
    | {
        userId: string;
        organisationId?: string;
        roles?: Array<string>;
        givenName?: string;
        familyName?: string;
        phoneNumber?: string;
        personalNumber?: string;
        email?: string;
        origin: 'agoy';
      }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{userId}/user-information/',
      path: {
        userId: userId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get the authorized client IDs for a user
   * @returns any List of client ID objects
   * @throws ApiError
   */
  public static getAuthorizedClientsForUser({
    userId,
  }: {
    /**
     * UUID of the user
     */
    userId: string;
  }): CancelablePromise<
    Array<{
      id: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{userId}/clients/authorized/',
      path: {
        userId: userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Authorize access to a user for a client
   * @returns void
   * @throws ApiError
   */
  public static authorizeClientForUser({
    userId,
    clientId,
  }: {
    /**
     * UUID of the user
     */
    userId: string;
    /**
     * UUID of the client
     */
    clientId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/{userId}/clients/{clientId}/authorized/',
      path: {
        userId: userId,
        clientId: clientId,
      },
      errors: {
        400: `Wrong user input`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete the authorization from a client for a user
   * @returns void
   * @throws ApiError
   */
  public static deleteAuthorizedClientsForUser({
    userId,
    clientId,
  }: {
    /**
     * UUID of the user
     */
    userId: string;
    /**
     * UUID of the client
     */
    clientId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/users/{userId}/clients/{clientId}/authorized/',
      path: {
        userId: userId,
        clientId: clientId,
      },
      errors: {
        400: `Wrong user input`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get the authorized client IDs for a user
   * @returns any List of clientperson ID objects
   * @throws ApiError
   */
  public static getAuthorizedClientPersonsForUser({
    userId,
  }: {
    /**
     * UUID of the user
     */
    userId: string;
  }): CancelablePromise<
    Array<{
      id: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{userId}/client-persons/authorized/',
      path: {
        userId: userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Authorize access to a user for a client
   * @returns void
   * @throws ApiError
   */
  public static authorizeClientPersonForUser({
    userId,
    clientId,
  }: {
    /**
     * UUID of the user
     */
    userId: string;
    /**
     * UUID of the client
     */
    clientId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/{userId}/client-persons/{clientId}/authorized/',
      path: {
        userId: userId,
        clientId: clientId,
      },
      errors: {
        400: `Wrong user input`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete the authorization from a clientPerson for a user
   * @returns void
   * @throws ApiError
   */
  public static deleteAuthorizedClientPersonForUser({
    userId,
    clientId,
  }: {
    /**
     * UUID of the user
     */
    userId: string;
    /**
     * UUID of the clientPerson
     */
    clientId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/users/{userId}/client-persons/{clientId}/authorized/',
      path: {
        userId: userId,
        clientId: clientId,
      },
      errors: {
        400: `Wrong user input`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get form quality checked history
   * @returns any OK
   * @throws ApiError
   */
  public static getFormQualityCheckedHistory({
    formId,
    clientId,
    financialYear,
  }: {
    /**
     * The id of the form you want to get the quality checked history.
     */
    formId: string;
    clientId: string;
    /**
     * Period in format yyyyMMdd-yyyyMMdd, e.g. 20210101-20211231
     */
    financialYear: string;
  }): CancelablePromise<{
    checked: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tax/forms/{formId}/quality-checked-history/',
      path: {
        formId: formId,
      },
      query: {
        clientId: clientId,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * gets the form Content.
   * @returns any the content of selected form
   * @throws ApiError
   */
  public static getFormContent({
    formId,
    financialYear,
  }: {
    /**
     * the Id of the form you want content for.
     */
    formId: string;
    /**
     * The Financial year you wish to get the forms for
     */
    financialYear: string;
  }): CancelablePromise<{
    id: string;
    parts: Array<
      | {
          type: 'empty';
          externalFields?: Array<TaxFormField>;
          pages: number;
        }
      | {
          type: 'form';
          id: string;
          name: string;
          externalFields?: Array<TaxFormField>;
          pages: Array<{
            imageUrl: string;
            fields: Array<TaxFormField>;
          }>;
        }
    >;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tax/forms/{formId}/',
      path: {
        formId: formId,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get all documents for a selected fiancial year in Skatt
   * @returns any List of documents
   * @throws ApiError
   */
  public static getTaxDeclarationDocuments({
    financialYear,
    clientId,
  }: {
    /**
     * The Financial year
     */
    financialYear: string;
    /**
     * The uuid of the client
     */
    clientId: string;
  }): CancelablePromise<
    Array<{
      id: string;
      type: string;
      status: string | null;
      name: string | null;
      parentId: string | null;
      financialYearId: number;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      checked?: boolean | null;
      relatedClients: Record<string, string>;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tax/documents/{clientId}/',
      path: {
        clientId: clientId,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get org chart with tax information
   * @returns any Ok
   * @throws ApiError
   */
  public static getTaxOrganisationChart({
    clientId,
    financialYear,
  }: {
    /**
     * id of the client (person or company)
     */
    clientId: string;
    financialYear: string;
  }): CancelablePromise<
    Array<{
      relationId: number;
      clientId: string;
      companyName: string;
      companyOrgNumber: string;
      companyType: string;
      shareInformationId?: number;
      sharePercentage?: number;
      hasDocuments: boolean;
      hasComments: boolean;
      isAccesible: boolean;
      forms: Array<{
        id: string;
        type: string;
        status: string | null;
        name: string | null;
        parentId: string | null;
        financialYearId: number;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
      }>;
      children?: Array<{
        relationId: number;
        clientId: string;
        companyName: string;
        companyOrgNumber: string;
        companyType: string;
        shareInformationId?: number;
        sharePercentage?: number;
        hasDocuments: boolean;
        hasComments: boolean;
        isAccesible: boolean;
        forms: Array<{
          id: string;
          type: string;
          status: string | null;
          name: string | null;
          parentId: string | null;
          financialYearId: number;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
        }>;
      }>;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tax/chart/{clientId}/',
      path: {
        clientId: clientId,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        400: `Bad Request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete a stock transaction
   * @returns void
   * @throws ApiError
   */
  public static deleteStockTransaction({
    transactionid,
  }: {
    /**
     * ID of the StockTransaction
     */
    transactionid: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/stock-transactions/{transactionid}/',
      path: {
        transactionid: transactionid,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't delete client`,
        500: `Server error`,
      },
    });
  }

  /**
   * EXAMPLE API, NOT TO BE USED IN PRODUCTION!
   * @returns any OK
   * @throws ApiError
   */
  public static getRandomId({
    id,
    query1,
    query2,
  }: {
    /**
     * Path param required
     */
    id: string;
    /**
     * Query param required
     */
    query1: string;
    /**
     * Query param not required
     */
    query2?: string;
  }): CancelablePromise<{
    status: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/random/{id}/',
      path: {
        id: id,
      },
      query: {
        query1: query1,
        query2: query2,
      },
    });
  }

  /**
   * Unassign a package from user
   * @returns any OK
   * @throws ApiError
   */
  public static unAssignPackageFromUser({
    userId,
  }: {
    /**
     * the Id of the user to be unassigned
     */
    userId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/payment/subscription/packages/users/{userId}/',
      path: {
        userId: userId,
      },
      errors: {
        400: `Bad request`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Remove a member from an organisaton. Note that this does not remove the user from the system!
   * @returns void
   * @throws ApiError
   */
  public static deleteOrganisationMember({
    memberId,
  }: {
    memberId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organisation/members/{memberId}/',
      path: {
        memberId: memberId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Accept an invite
   * @returns any Invite accepted
   * @throws ApiError
   */
  public static acceptOrganisationInvite({
    inviteId,
  }: {
    /**
     * UUID of the invite
     */
    inviteId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organisation/invites/{inviteId}/',
      path: {
        inviteId: inviteId,
      },
      errors: {
        400: `Not found`,
        401: `Not authenticated`,
        404: `Invite not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete an invite
   * @returns void
   * @throws ApiError
   */
  public static deleteOrganisationInvite({
    inviteId,
  }: {
    /**
     * UUID of the invite
     */
    inviteId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organisation/invites/{inviteId}/',
      path: {
        inviteId: inviteId,
      },
      errors: {
        400: `Not found`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Invite not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update checklist belonging to organisation.
   * @returns Checklist Checklist updated
   * @throws ApiError
   */
  public static updateChecklistTemplate({
    checklistId,
    requestBody,
  }: {
    /**
     * UUID of the checklist
     */
    checklistId: number;
    /**
     * Required checklist attributes for update checklist.
     */
    requestBody: {
      id: number;
      name: string;
      description: string;
      type: 'empty' | 'tax' | 'annual-report' | 'tax-declaration';
      state: 'active' | 'inactive';
      program:
        | 'reconciliation_fin_statement_company'
        | 'annual_report_company'
        | 'tax_declaration_company'
        | 'tax_declaration_person';
      organisationId?: string;
      groups: Array<{
        id?: number;
        label: string;
        periodicity:
          | 'every_period'
          | 'even_month'
          | 'odd_month'
          | 'every_quarter'
          | 'half_year'
          | 'last_period'
          | 'first_period';
        order?: number;
        fromAccount?: string;
        toAccount?: string;
        questions: Array<{
          id?: number;
          label: string;
          periodicity:
            | 'every_period'
            | 'even_month'
            | 'odd_month'
            | 'every_quarter'
            | 'half_year'
            | 'last_period'
            | 'first_period';
          order?: number;
          fromAccount?: string;
          toAccount?: string;
        }>;
      }>;
      clientIds: Array<string>;
    };
  }): CancelablePromise<Checklist> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organisation/checklists/{checklistId}/',
      path: {
        checklistId: checklistId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update org member roles
   * @returns void
   * @throws ApiError
   */
  public static updateOrgMemberRoles({
    memberId,
    requestBody,
  }: {
    /**
     * UUID of the org member
     */
    memberId: string;
    requestBody: {
      role: 'OrganisationAdmin' | 'AccountingConsultant';
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organisation-members/{memberId}/roles/',
      path: {
        memberId: memberId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Gets the inventoryObsolescence for a requested period
   * @returns any Ok
   * @throws ApiError
   */
  public static getInventoryObsolescence({
    clientId,
    periodId,
    account,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientId: string;
    /**
     * Id of the period you request inventory obsolescence
     */
    periodId: number;
    /**
     * The account number for which you want the Inventory Obsolescence.
     */
    account: number;
  }): CancelablePromise<{
    id: number;
    clientId: string;
    account: number;
    reference: string | null;
    periodId: number;
    rate: number;
    description: string | null;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/inventory-obsolescence/{clientId}/',
      path: {
        clientId: clientId,
      },
      query: {
        periodId: periodId,
        account: account,
      },
      errors: {
        400: `Bad Request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update a Inventory Obsolescence object for the hidden row.
   * @returns void
   * @throws ApiError
   */
  public static updateInventoryObsolescence({
    clientId,
    requestBody,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientId: string;
    requestBody: {
      account: number;
      reference?: string;
      periodId: number;
      rate: number;
      description?: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/inventory-obsolescence/{clientId}/',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * delete an inventory obsolescence
   * @returns void
   * @throws ApiError
   */
  public static deleteInventoryObsolescence({
    clientId,
    id,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientId: string;
    /**
     * the id of the IO you wish to remove.
     */
    id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/inventory-obsolescence/{clientId}/',
      path: {
        clientId: clientId,
      },
      query: {
        id: id,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Inventory obsolescence not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Adds a Inventory Obsolescence object for the hidden row.
   * @returns any Created
   * @throws ApiError
   */
  public static addInventoryObsolescence({
    clientId,
    requestBody,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientId: string;
    requestBody: {
      account: number;
      reference?: string;
      periodId: number;
      rate: number;
      description?: string;
    };
  }): CancelablePromise<{
    id: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/inventory-obsolescence/{clientId}/',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns the state of a feature flag for a user
   * @returns any OK
   * @throws ApiError
   */
  public static getFeatureFlag({
    flag,
  }: {
    /**
     * Feature flag name
     */
    flag: string;
  }): CancelablePromise<boolean | string | number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/features/{flag}',
      path: {
        flag: flag,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Fetch company information from Bolagsverket
   * @returns any The base information from Bolagsverket
   * @throws ApiError
   */
  public static getBvCompanyInformation({
    clientId,
  }: {
    /**
     * the id of the clientCompany you wish to fetch information for.
     */
    clientId: string;
  }): CancelablePromise<{
    /**
     * The organisation number of the company
     */
    orgnr: string;
    /**
     * The name of the company
     */
    namn: string;
    /**
     * The status of the company
     */
    status: Array<{
      kod: string | null;
      text: string | null;
      datum: string | null;
    }>;
    rakenskapsperioder: Array<{
      /**
       * The start date of the accounting period
       */
      from: string | null;
      /**
       * The end date of the accounting period
       */
      tom: string | null;
      /**
       * If the company is required to have a revision report
       */
      kravPaRevisionsberattelse: 'ja' | 'nej' | 'uppgift_saknas';
    }>;
    foretradare: Array<{
      /**
       * The first name of the person
       */
      fornamn: string | null;
      /**
       * The last name of the person
       */
      namn: string;
      /**
       * The social security number of the person
       */
      personnummer: string | null;
      /**
       * The other identity of the person
       */
      annanIdentitet: string | null;
      /**
       * The functions of the person
       */
      funktioner: Array<{
        /**
         * The role of the person
         */
        kod: string;
        text: string;
      }>;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/company-info/{clientId}/bolagsverket/base-data/',
      path: {
        clientId: clientId,
      },
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * gets the comments for the client
   * @returns any Ok
   * @throws ApiError
   */
  public static getCommentsForClient({
    clientId,
    program,
    financialYearId,
    periodId,
    pageSize,
    offset,
    key,
  }: {
    /**
     * UUID of the client
     */
    clientId: string;
    /**
     * key related to the comment
     */
    program:
      | 'RECONCILIATION'
      | 'FIN_STATEMENT'
      | 'FIN_STATEMENT_REPORT'
      | 'AN_REPORT'
      | 'TAX_DECLARATION';
    /**
     * Id of the finanical year
     */
    financialYearId?: number;
    /**
     * Id of the period
     */
    periodId?: number;
    /**
     * number of comments to return
     */
    pageSize?: number;
    /**
     * From which page to return.
     */
    offset?: number;
    /**
     * key related to the comment
     */
    key?: string;
  }): CancelablePromise<
    Array<{
      id: number;
      userId: string;
      userDisplayName: string;
      comment: string;
      program:
        | 'RECONCILIATION'
        | 'FIN_STATEMENT'
        | 'FIN_STATEMENT_REPORT'
        | 'AN_REPORT'
        | 'TAX_DECLARATION';
      createdAt: string;
      periodId: number | null;
      financialYearId: number | null;
      key: string | null;
      copiedFrom: {
        id: number;
        userId: string;
        comment: string;
        program:
          | 'RECONCILIATION'
          | 'FIN_STATEMENT'
          | 'FIN_STATEMENT_REPORT'
          | 'AN_REPORT'
          | 'TAX_DECLARATION';
        createdAt: string;
        periodId: number | null;
        financialYearId: number | null;
        key: string | null;
      } | null;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/comments/{clientId}/',
      path: {
        clientId: clientId,
      },
      query: {
        financialYearId: financialYearId,
        periodId: periodId,
        pageSize: pageSize,
        offset: offset,
        key: key,
        program: program,
      },
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't find client`,
        500: `Server error`,
      },
    });
  }

  /**
   * updating an existing comment for a client.
   * @returns any Created
   * @throws ApiError
   */
  public static updateComment({
    clientId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientId: string;
    requestBody: {
      userId: string;
      commentId: number;
      program:
        | 'RECONCILIATION'
        | 'FIN_STATEMENT'
        | 'FIN_STATEMENT_REPORT'
        | 'AN_REPORT'
        | 'TAX_DECLARATION';
      financialYearId?: number;
      periodId?: number;
      key?: string;
      comment: string;
    };
  }): CancelablePromise<{
    id: number;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/comments/{clientId}/',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `comment not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Adding comments for a client.
   * @returns any Created
   * @throws ApiError
   */
  public static addComments({
    clientId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientId: string;
    requestBody: Array<{
      userId: string;
      program:
        | 'RECONCILIATION'
        | 'FIN_STATEMENT'
        | 'FIN_STATEMENT_REPORT'
        | 'AN_REPORT'
        | 'TAX_DECLARATION';
      financialYearId?: number;
      periodId?: number;
      key?: string;
      copiedFromId?: number;
      comment: string;
    }>;
  }): CancelablePromise<{
    id: Array<number>;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/comments/{clientId}/',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Organisation not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Bulk delete comments
   * @returns void
   * @throws ApiError
   */
  public static deleteCommentsBulk({
    clientId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientId: string;
    requestBody: {
      commentIds: Array<number>;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/comments/{clientId}/',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Comment not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get voucher series
   * @returns any OK
   * @throws ApiError
   */
  public static getVoucherSeries({
    clientid,
    period,
  }: {
    /**
     * Client ID, Example: 3d6263ea-aced-40be-bc8f-f5758cd5fa45
     */
    clientid: string;
    period: string;
  }): CancelablePromise<
    Array<{
      series: string;
      description: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/vouchers/series/',
      path: {
        clientid: clientid,
      },
      query: {
        period: period,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get next voucher number by series
   * @returns any OK
   * @throws ApiError
   */
  public static getVoucherNextNumber({
    clientid,
    financialYearId,
    series,
  }: {
    /**
     * Client ID, Example: 3d6263ea-aced-40be-bc8f-f5758cd5fa45
     */
    clientid: string;
    financialYearId: number;
    /**
     * Voucher Series
     */
    series: string;
  }): CancelablePromise<{
    nextNumber?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/vouchers/number/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYearId: financialYearId,
        series: series,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get account list
   * @returns any OK
   * @throws ApiError
   */
  public static getAccountList({
    clientid,
    source,
    date,
  }: {
    /**
     * Client ID, Example: 3d6263ea-aced-40be-bc8f-f5758cd5fa45
     */
    clientid: string;
    /**
     * Source of accounts
     */
    source: string;
    /**
     * Date of needed year in YYYY-MM-DD format
     */
    date?: string;
  }): CancelablePromise<
    Array<{
      number: number;
      name: string;
      active: boolean;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/vouchers/accounts/',
      path: {
        clientid: clientid,
      },
      query: {
        source: source,
        date: date,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update voucher
   * @returns any OK
   * @throws ApiError
   */
  public static updateVoucher({
    clientid,
    voucherid,
    requestBody,
  }: {
    /**
     * Client ID, Example: 3d6263ea-aced-40be-bc8f-f5758cd5fa45
     */
    clientid: string;
    voucherid: number;
    /**
     * Voucher data as JSON
     */
    requestBody: any;
  }): CancelablePromise<{
    id?: number;
    period?: string;
    series: string;
    serNumber: number;
    transDate: string;
    source: 'agoy' | 'fortnox' | 'visma';
    reference?: VoucherReference;
    preliminary?: boolean;
    description?: string;
    attachments?: Array<{
      path: string;
      name: string;
    }>;
    transactions?: Array<{
      id: number;
      sourceInfo?: string;
      kind?: string;
      typeAmount: 'debit' | 'credit';
      amount: number;
      account: number;
      pr?: string | null;
      ks?: string | null;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/vouchers/{voucherid}/',
      path: {
        clientid: clientid,
        voucherid: voucherid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Remove voucher
   * @returns void
   * @throws ApiError
   */
  public static deleteVoucher({
    clientid,
    voucherid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    voucherid: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/vouchers/{voucherid}/',
      path: {
        clientid: clientid,
        voucherid: voucherid,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create voucher
   * @returns any OK
   * @throws ApiError
   */
  public static createVoucher({
    clientid,
    requestBody,
  }: {
    /**
     * Client ID, Example: 3d6263ea-aced-40be-bc8f-f5758cd5fa45
     */
    clientid: string;
    /**
     * Voucher data as JSON
     */
    requestBody: any;
  }): CancelablePromise<{
    id?: number;
    series: string;
    serNumber: number;
    transDate: string;
    source: 'agoy' | 'fortnox' | 'visma';
    description?: string;
    preliminary?: boolean;
    reference?: VoucherReference;
    attachments?: Array<{
      path: string;
      name: string;
    }>;
    transactions: Array<{
      id: number;
      sourceInfo: string;
      kind: string;
      typeAmount: 'debit' | 'credit';
      amount: number;
      account: number;
      pr?: string | null;
      ks?: string | null;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/vouchers/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get finacial year vouchers
   * @returns any OK
   * @throws ApiError
   */
  public static getVoucherList({
    clientid,
    period,
    reference,
    series,
  }: {
    /**
     * Client ID, Example: 3d6263ea-aced-40be-bc8f-f5758cd5fa45
     */
    clientid: string;
    period: string;
    reference?: VoucherReference;
    series?: Array<string>;
  }): CancelablePromise<
    Array<{
      id: number;
      series: string;
      serNumber: number;
      transDate: string;
      source: 'agoy' | 'fortnox' | 'visma';
      description?: string;
      preliminary?: boolean;
      reference?: VoucherReference;
      attachments?: Array<{
        id: number;
        path: string;
        name: string;
      }>;
      transactions?: Array<{
        id: number;
        sourceInfo?: string;
        kind?: string;
        typeAmount: 'debit' | 'credit';
        amount: number;
        account: number;
        pr?: string | null;
        ks?: string | null;
      }>;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/vouchers/',
      path: {
        clientid: clientid,
      },
      query: {
        period: period,
        reference: reference,
        series: series,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get users list
   * @returns CognitoUser successful operation
   * @throws ApiError
   */
  public static getClientsUsers({
    clientid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
  }): CancelablePromise<Array<CognitoUser>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/users/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get list of unpaid supplier invoices from fortnox
   * @returns any List of unpaid supplier invoices from fortnox
   * @throws ApiError
   */
  public static getUnpaidSupplierInvoices({
    clientid,
    date,
  }: {
    clientid: string;
    date: string;
  }): CancelablePromise<{
    statusList: Array<string>;
    invoices: Array<{
      id: number;
      voucherId: number | null;
      clientId: string;
      invoiceNumber: number;
      supplierNumber: string;
      supplierName: string;
      date: string;
      dueDate?: string;
      paymentDate?: string;
      total: number;
      balance: number;
      currency: string;
      status: string;
      source: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/unpaid-supplier-invoices/',
      path: {
        clientid: clientid,
      },
      query: {
        date: date,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get list of unpaid customer invoices
   * @returns any List of unpaid customer invoices
   * @throws ApiError
   */
  public static getUnpaidCustomerInvoices({
    clientid,
    date,
  }: {
    clientid: string;
    date: string;
  }): CancelablePromise<{
    statusList: Array<string>;
    invoices: Array<{
      id: number;
      voucherId: number | null;
      clientId: string;
      invoiceNumber: number;
      customerNumber: string;
      customerName: string;
      total: number;
      balance: number;
      currency: string;
      date: string;
      dueAt: string;
      paidAt: string | null;
      ocr: string;
      status: string;
      source: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/unpaid-customer-invoices/',
      path: {
        clientid: clientid,
      },
      query: {
        date: date,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add account Underlag from Bokslut
   * @returns number Successfully updated accounts
   * @throws ApiError
   */
  public static postAccountBasis({
    clientid,
    periodId,
    requestBody,
  }: {
    clientid: string;
    periodId: number;
    requestBody: Array<{
      account: number;
      document: {
        name: string;
        documentType: string;
        footer: string;
        header: string;
        parts: Array<string>;
        printState: any;
      };
      legacySpecification?: {
        reference: string;
        description: string;
        account: string;
        amount: number | null;
      };
    }>;
  }): CancelablePromise<Array<number>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/tax/postAccountBasis',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get accounts by group
   * @returns any Account Groups
   * @throws ApiError
   */
  public static getAccountsByGroups({
    clientid,
    periodId,
    financialYearId,
  }: {
    clientid: string;
    periodId: number;
    financialYearId: number;
  }): CancelablePromise<{
    groups: Array<AccountGroups>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/tax/accountsByGroups',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        financialYearId: financialYearId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get tax config for year
   * @returns TaxCalculationConfig OK
   * @throws ApiError
   */
  public static getClientsTax({
    clientid,
    financialYear,
  }: {
    clientid: string;
    financialYear: string;
  }): CancelablePromise<TaxCalculationConfig> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/tax/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        400: `Invalid ID`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't find tax config`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add tax config for year
   * @returns void
   * @throws ApiError
   */
  public static postClientsTax({
    clientid,
    financialYear,
    requestBody,
  }: {
    clientid: string;
    financialYear: string;
    requestBody: TaxCalculationConfig;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/tax/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYear: financialYear,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid ID`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't add tax config`,
        500: `Server error`,
      },
    });
  }

  /**
   * Send supplier invoice report
   * @returns any OK
   * @throws ApiError
   */
  public static supplierInvoiceReport({
    clientid,
    requestBody,
  }: {
    clientid: string;
    requestBody: {
      date: string;
      report: string;
    };
  }): CancelablePromise<{
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/supplier-invoices/report/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Server error`,
      },
    });
  }

  /**
   * Send SQS event to update supplier invoices in DB using lambda
   * @returns void
   * @throws ApiError
   */
  public static sendSqsSupplierInvoicesEvent({
    clientid,
    invoicesType = 'supplier',
  }: {
    clientid: string;
    invoicesType?: 'supplier' | 'both';
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/supplier-invoices/lambda/',
      path: {
        clientid: clientid,
      },
      query: {
        invoicesType: invoicesType,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get supplier invoice attachment
   * @returns any Attachment
   * @throws ApiError
   */
  public static getSupplierInvoiceAttachment({
    clientid,
    invoiceid,
  }: {
    clientid: string;
    invoiceid: string;
  }): CancelablePromise<{
    fileContent: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/supplier-invoices/{invoiceid}/attachment/',
      path: {
        clientid: clientid,
        invoiceid: invoiceid,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get list of supplier invoices from fortnox
   * @returns any OK
   * @throws ApiError
   */
  public static getSupplierInvoices({
    clientid,
    date,
  }: {
    clientid: string;
    /**
     * End of month in YYYY-MM-DD format
     */
    date: string;
  }): CancelablePromise<{
    statusList: Array<string>;
    invoices: Array<{
      id: number;
      voucherId: number | null;
      clientId: string;
      invoiceNumber: number;
      supplierNumber: string;
      supplierName: string;
      date: string;
      dueAt: string | null;
      total: number;
      balance: number;
      currency: string;
      currencyRate: number;
      status: 'unpaid' | 'cancelled' | 'paid' | 'unbooked' | 'unpaid_overdue';
      source: string;
      paidAt: string | null;
    }>;
    reportData: {
      totalInvoices: number;
      totalSumSEK: number;
      sumSEK: number;
      updateDate?: string;
      updateState?: 'completed' | 'running' | 'invoices_fail' | 'payments_fail';
      sums: Record<string, number>;
      supplierInvoices: Array<{
        supplierName: string;
        supplierNumber: string;
        amount: number;
        totalSumSEK: number;
        invoices: Array<{
          invoiceNumber: number;
          date: string;
          dueAt: string;
          currency: string;
          total: number;
          totalSEK: number;
          saldo: number;
          saldoSEK: number;
        }>;
      }>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/supplier-invoices/',
      path: {
        clientid: clientid,
      },
      query: {
        date: date,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Persist in DB fortnox supplier invoices
   * @returns void
   * @throws ApiError
   */
  public static persistSupplierInvoices({
    clientid,
    year,
    requestBody,
  }: {
    clientid: string;
    year: string;
    requestBody: {
      action: 'update-db';
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/supplier-invoices/',
      path: {
        clientid: clientid,
      },
      query: {
        year: year,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Deletes the StockTotal for a given company client
   * @returns void
   * @throws ApiError
   */
  public static deleteStockTotal({
    clientid,
    stockid,
  }: {
    /**
     * UUID of the clientCompany
     */
    clientid: string;
    /**
     * id of the stockTotal
     */
    stockid: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/stock-total/{stockid}/',
      path: {
        clientid: clientid,
        stockid: stockid,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add stockTotal to an already existing companyClient
   * @returns any OK
   * @throws ApiError
   */
  public static addStockTotal({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    requestBody: {
      shareTotal: number;
      type: 'new_registration' | 'purchase' | 'new_emission' | 'other';
      date: string;
      comment?: string;
    };
  }): CancelablePromise<{
    id: number;
    clientCompanyId: string;
    shareTotal: number;
    type:
      | 'new_registration'
      | 'purchase'
      | 'new_emission'
      | 'other'
      | 'comment';
    date: string;
    comment?: string | null;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/stock-total/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't create client`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get transactions from skatteverket
   * @returns any Ok
   * @throws ApiError
   */
  public static getClientsSkatteverketTransactions({
    clientid,
    orgNumber,
    fromDate,
    toDate,
    type,
    offset,
    limit,
    contains,
  }: {
    /**
     * UUID of the user
     */
    clientid: string;
    /**
     * organization number
     */
    orgNumber: string;
    fromDate: Array<string>;
    toDate: Array<string>;
    /**
     * `soft`, return transactions from DB. `hard`, fetch transactions from Skatteverket, update DB and return.
     */
    type?: 'soft' | 'hard';
    /**
     * pagination offset
     */
    offset?: number;
    /**
     * pagination limit
     */
    limit?: number;
    /**
     * text to search for transactions starting with it
     */
    contains?: string;
  }): CancelablePromise<
    | {
        totalResults: number;
        totalPages: number;
        currentPage: number;
        lastUpdateDate: string | null;
        transactions: Array<{
          transactionDate: string | null;
          specification: string;
          amount: number;
          saldo?: number;
        }>;
      }
    | {
        url: string;
        randomState: string;
      }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/skatteverket/transactions/',
      path: {
        clientid: clientid,
      },
      query: {
        type: type,
        orgNumber: orgNumber,
        offset: offset,
        limit: limit,
        fromDate: fromDate,
        toDate: toDate,
        contains: contains,
      },
      errors: {
        400: `Invalid ID`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Imports the latest SIE-file for a financial year
   * @returns any SieFile import started
   * @throws ApiError
   */
  public static retryImportSieFile({
    clientid,
    financialYear,
  }: {
    clientid: string;
    financialYear: string;
  }): CancelablePromise<{
    jobId: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/sie-files/retry',
      path: {
        clientid: clientid,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Fetch SieFile of a client, for a given year
   * @returns binary String containing the SieFile contents
   * @throws ApiError
   */
  public static getSieFile({
    clientid,
    financialYear,
  }: {
    clientid: string;
    financialYear: string;
  }): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/sie-files/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Store SieFile in S3. for a client and a given year
   * @returns any SieFile import started
   * @throws ApiError
   */
  public static putSieFile({
    clientid,
    financialYear,
    orgNumber,
    requestBody,
  }: {
    clientid: string;
    financialYear: string;
    orgNumber: string;
    /**
     * SieFile as string
     */
    requestBody: any;
  }): CancelablePromise<{
    jobId: string | null;
    financialYearId?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/sie-files/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYear: financialYear,
        orgNumber: orgNumber,
      },
      body: requestBody,
      mediaType: 'application/octet-stream',
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get routines
   * @returns string Routines
   * @throws ApiError
   */
  public static getRoutines({
    clientid,
  }: {
    clientid: string;
  }): CancelablePromise<Record<string, Record<string, string>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/routines/',
      path: {
        clientid: clientid,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Save routine
   * @returns void
   * @throws ApiError
   */
  public static addRoutine({
    clientid,
    requestBody,
  }: {
    clientid: string;
    requestBody: {
      account?: string;
      group?: string;
      input: Record<string, string>;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/routines/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Copy or move comments and specifications
   * @returns void
   * @throws ApiError
   */
  public static copyUserInput({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    requestBody: {
      targetAccount: number;
      targetPeriodId: number;
      sourceAccount: number;
      sourcePeriodId: number;
      information: Array<'comment' | 'specification'>;
      /**
       * Use 'move' to remove the content from the source.
       */
      operation: 'copy' | 'move';
      /**
       * True to replace the content at the target, only applies to specifications. False to append the content.
       */
      overwrite: boolean;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/reconciliation/user-input/copy',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Put user input data
   * @returns any User input data
   * @throws ApiError
   */
  public static putUserInput({
    clientid,
    periodId,
    requestBody,
    accountNumber,
    accountGroup,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    requestBody: {
      visited?: boolean;
      comment?: string | null;
      printComment?: boolean;
      inventoryView?: any;
      cashView?: any;
    };
    accountNumber?: number;
    accountGroup?: string;
  }): CancelablePromise<{
    accountPeriodId: number;
    visited: boolean;
    comment: string | null;
    printComment: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/reconciliation/user-input/',
      path: {
        clientid: clientid,
      },
      query: {
        accountNumber: accountNumber,
        accountGroup: accountGroup,
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Put user input data
   * @returns any User input data
   * @throws ApiError
   */
  public static getPeriodUserInput({
    clientid,
    periodId,
    accountNumbers,
    accountGroups,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    accountNumbers?: Array<number>;
    accountGroups?: Array<string>;
  }): CancelablePromise<{
    accounts: Record<
      string,
      {
        visited: boolean;
        comment: string | null;
        printComment: boolean;
      }
    >;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/reconciliation/user-input/',
      path: {
        clientid: clientid,
      },
      query: {
        accountNumbers: accountNumbers,
        accountGroups: accountGroups,
        periodId: periodId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get account balances with grouping and key figures for reconciliation
   * @returns any Reconciliation user input
   * @throws ApiError
   */
  public static getReconciliationUserInput({
    clientid,
    start,
    end,
    groupBy,
  }: {
    clientid: string;
    start: string;
    end: string;
    groupBy: 'month' | 'quarter' | 'financialYear';
  }): CancelablePromise<{
    groups: Array<{
      start: string;
      end: string;
      /**
       * account number to user input mapping
       */
      accounts: Record<
        string,
        {
          /**
           * Has the account been visited in the last period of the group
           */
          visited: boolean;
          /**
           * The external comment of the last period
           */
          comment: string | null;
          /**
           * Include the comment in printing?
           */
          printComment: boolean;
          /**
           * Count of period_documents for the grouped periods
           */
          periodDocuments: number;
          /**
           * Count of internal comments for the grouped periods
           */
          internalComments: number;
          /**
           * Specifications of the last period
           */
          legacySpecifications: Array<{
            id: number;
            reference: string;
            description: string;
            account: string;
            amount: number | null;
          }>;
          actualBalance?: {
            final?: {
              /**
               * actual_balance.balance for actual_balance.preliminary = false of the last period
               */
              balance: number | null;
              /**
               * actual_balance.checked for actual_balance.preliminary = false of the last period
               */
              checked: boolean;
            };
            preliminary?: {
              /**
               * actual_balance.balance for actual_balance.preliminary = true of the last period
               */
              balance: number | null;
              /**
               * actual_balance.checked for actual_balance.preliminary = true of the last period
               */
              checked: boolean;
            };
          };
        }
      >;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/reconciliation/user-input-overview',
      path: {
        clientid: clientid,
      },
      query: {
        start: start,
        end: end,
        groupBy: groupBy,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Remove a selected column from a specification
   * @returns void
   * @throws ApiError
   */
  public static deleteSpecificationSelectedColumn({
    clientid,
    specificationId,
    selectedColumnId,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Id of specification
     */
    specificationId: number;
    /**
     * Id of the selected column to be removed
     */
    selectedColumnId: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/reconciliation/specifications/{specificationId}/selected-columns/{selectedColumnId}/',
      path: {
        clientid: clientid,
        specificationId: specificationId,
        selectedColumnId: selectedColumnId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add a column as selected column for a specification
   * @returns any Selected column added
   * @throws ApiError
   */
  public static addSpecificationSelectedColumn({
    clientid,
    specificationId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Id of specification
     */
    specificationId: number;
    requestBody: {
      /**
       * Id of the column to be added as selected column
       */
      columnId: number;
    };
  }): CancelablePromise<{
    columnId: number;
    order: number;
    specificationId: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/reconciliation/specifications/{specificationId}/selected-columns/',
      path: {
        clientid: clientid,
        specificationId: specificationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create a new cell in a specification row
   * @returns any Ok
   * @throws ApiError
   */
  public static addSpecificationCell({
    clientid,
    specificationId,
    rowId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Id of specification
     */
    specificationId: number;
    /**
     * Id of the row
     */
    rowId: number;
    requestBody: {
      columnId: number;
      value: string;
    };
  }): CancelablePromise<{
    rowId: number;
    columnId: number;
    value: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/reconciliation/specifications/{specificationId}/rows/{rowId}/cells/',
      path: {
        clientid: clientid,
        specificationId: specificationId,
        rowId: rowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update a cell in a specification row
   * @returns any Ok
   * @throws ApiError
   */
  public static updateSpecificationCell({
    clientid,
    specificationId,
    rowId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Id of specification
     */
    specificationId: number;
    /**
     * Id of the row
     */
    rowId: number;
    requestBody: {
      columnId: number;
      value: string;
    };
  }): CancelablePromise<{
    rowId: number;
    columnId: number;
    value: string;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/reconciliation/specifications/{specificationId}/rows/{rowId}/cells/',
      path: {
        clientid: clientid,
        specificationId: specificationId,
        rowId: rowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create a new specification row
   * @returns any Ok
   * @throws ApiError
   */
  public static addSpecificationRow({
    clientid,
    specificationId,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Id of specification
     */
    specificationId: number;
  }): CancelablePromise<{
    id: number;
    order?: number;
    specificationId: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/reconciliation/specifications/{specificationId}/rows/',
      path: {
        clientid: clientid,
        specificationId: specificationId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update multiple specification rows order.
   * @returns void
   * @throws ApiError
   */
  public static updateSpecificationRowsOrder({
    clientid,
    specificationId,
    requestBody,
  }: {
    /**
     * The UUID of the client
     */
    clientid: string;
    /**
     * Id of specification
     */
    specificationId: number;
    requestBody: Array<{
      /**
       * The id of the specification row
       */
      id: number;
      /**
       * The order of the row
       */
      order: number;
    }>;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/clients/{clientid}/reconciliation/specifications/{specificationId}/rows/',
      path: {
        clientid: clientid,
        specificationId: specificationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Delete rows and the cells connected to the rows, in a specification.
   * @returns void
   * @throws ApiError
   */
  public static deleteRows({
    clientid,
    specificationId,
    rowIds,
  }: {
    /**
     * The id of the client
     */
    clientid: string;
    /**
     * Id of specification
     */
    specificationId: number;
    /**
     * List of row ids to delete
     */
    rowIds: Array<number>;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/reconciliation/specifications/{specificationId}/rows/',
      path: {
        clientid: clientid,
        specificationId: specificationId,
      },
      query: {
        rowIds: rowIds,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get list of specifications for list of accounts and a period
   * @returns any Ok
   * @throws ApiError
   */
  public static getSpecifications({
    clientid,
    periodId,
    accountNumbers,
  }: {
    /**
     * Client UUID
     */
    clientid: string;
    /**
     * Period Id
     */
    periodId: number;
    /**
     * List of account numbers
     */
    accountNumbers: Array<number>;
  }): CancelablePromise<{
    accounts: Record<
      string,
      {
        specification: {
          id?: number | null;
          sortColumnId?: number | null;
          sortOrder?: 'ASC' | 'DESC' | null;
          actualBalanceColumnId?: number | null;
        };
        columns: Array<{
          id: number;
          name: string;
          contentType: string;
        }>;
        rows: Array<{
          id: number;
          order: number;
          cells: Array<{
            columnId: number;
            rowId: number;
            value: string;
          }>;
        }>;
      }
    >;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/reconciliation/specifications/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        accountNumbers: accountNumbers,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create a new specification
   * @returns any Specification created successfully
   * @throws ApiError
   */
  public static addSpecification({
    clientid,
    accountNumber,
    periodId,
  }: {
    /**
     * The id of the client
     */
    clientid: string;
    /**
     * The account number
     */
    accountNumber: number;
    /**
     * The id of the period
     */
    periodId: number;
  }): CancelablePromise<{
    id: number;
    accountId: number;
    actualBalanceColumnId: number | null;
    periodId: number;
    sortColumnId: number | null;
    sortOrder: 'ASC' | 'DESC' | null;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/reconciliation/specifications/',
      path: {
        clientid: clientid,
      },
      query: {
        accountNumber: accountNumber,
        periodId: periodId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Unique constraint violation`,
        500: `Server error`,
      },
    });
  }

  /**
   * Deletes a specification user column by its id.
   * @returns void
   * @throws ApiError
   */
  public static deleteUserSpecificationColumn({
    clientid,
    columnId,
    periodId,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Id of the specification column to delete
     */
    columnId: number;
    /**
     * Id of the period to delete the column from and onwards
     */
    periodId: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/reconciliation/specification-columns/{columnId}/',
      path: {
        clientid: clientid,
        columnId: columnId,
      },
      query: {
        periodId: periodId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Creates a new user specification column.
   * @returns any Specification user column created successfully
   * @throws ApiError
   */
  public static addUserSpecificationColumn({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    requestBody: {
      /**
       * The category id of the specification column
       */
      categoryId: number;
      /**
       * The content type of the specification column
       */
      contentType: 'TEXT' | 'AMOUNT' | 'DATE' | 'PERCENTAGE';
      /**
       * Whether the specification column is pinned
       */
      isPinned: boolean;
      /**
       * The name of the specification column
       */
      name: string;
      /**
       * The period id
       */
      periodId: number;
    };
  }): CancelablePromise<{
    id: number;
    categoryId: number;
    contentType: 'TEXT' | 'AMOUNT' | 'DATE' | 'PERCENTAGE';
    isPinned: boolean;
    name: string;
    order: number;
    type: 'USER';
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/reconciliation/specification-columns/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Get account balances with grouping and key figures for reconciliation
   * @returns any Reconciliation balances
   * @throws ApiError
   */
  public static getReconciliationOverview({
    clientid,
    start,
    end,
    groupBy,
    includeYearEnd = false,
  }: {
    clientid: string;
    start: string;
    end: string;
    groupBy: 'month' | 'quarter' | 'financialYear';
    includeYearEnd?: boolean;
  }): CancelablePromise<{
    groups: Array<ReconciliationBalances>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/reconciliation/overview',
      path: {
        clientid: clientid,
      },
      query: {
        start: start,
        end: end,
        groupBy: groupBy,
        includeYearEnd: includeYearEnd,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get list of legacy specifications for list of accounts
   * @returns any Ok
   * @throws ApiError
   */
  public static getLegacySpecifications({
    clientid,
    periodId,
    accountNumbers,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    accountNumbers: Array<number>;
  }): CancelablePromise<{
    accounts: Record<
      string,
      Array<{
        id: number;
        description: string;
        reference: string;
        account: string;
        amount: number | null;
      }>
    >;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/reconciliation/legacy-specifications/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        accountNumbers: accountNumbers,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update list of legacy specifications
   * @returns any Ok
   * @throws ApiError
   */
  public static updateLegacySpecifications({
    clientid,
    periodId,
    accountNumber,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    accountNumber: number;
    requestBody: Array<{
      /**
       * specification row id, ignored but allowed
       */
      id?: number;
      reference: string;
      description: string;
      account: string;
      amount: number | null;
    }>;
  }): CancelablePromise<{
    specifications: Array<{
      id: number;
      reference: string;
      description: string;
      account: string;
      amount: number | null;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/reconciliation/legacy-specifications/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        accountNumber: accountNumber,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Fill actual balances for a period
   * @returns any Ok
   * @throws ApiError
   */
  public static fillActualBalance({
    clientid,
    periodId,
    type,
    notChangedPeriods,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    type: 'all' | 'not_changed';
    notChangedPeriods?: Array<number>;
  }): CancelablePromise<{
    /**
     * Map of account numbers
     */
    accounts: Record<
      string,
      {
        /**
         * Preliminary actual balance with quality check
         */
        preliminary?: {
          checked: boolean;
          balance: number | null;
        };
        /**
         * Regular actual balance with quality check
         */
        final?: {
          checked: boolean;
          balance: number | null;
        };
      }
    >;
    /**
     * Flag that indicates whether the client has specifications
     */
    hasSpecifications?: boolean | null;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/reconciliation/fill-actual-balance/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        notChangedPeriods: notChangedPeriods,
        type: type,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update (preliminary) actual balance for an account and period
   * @returns any Ok
   * @throws ApiError
   */
  public static putActualBalance({
    clientid,
    periodId,
    account,
    preliminary,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    account: Array<number>;
    preliminary: boolean;
    requestBody: {
      balance?: number | null | 'current';
      /**
       * Quality checked
       */
      checked?: boolean;
    };
  }): CancelablePromise<
    Array<{
      periodId: number;
      account: number;
      preliminary: boolean;
      balance: number | null;
      checked: boolean;
    }>
  > {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/reconciliation/actual-balance',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        account: account,
        preliminary: preliminary,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get the (preliminary) actual balances for a period
   * @returns any Ok
   * @throws ApiError
   */
  public static getActualBalances({
    clientid,
    periodId,
    accounts,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    accounts: Array<number>;
  }): CancelablePromise<{
    /**
     * Map of account numbers
     */
    accounts: Record<
      string,
      {
        /**
         * Preliminary actual balance with quality check
         */
        preliminary?: {
          checked: boolean;
          balance: number | null;
        };
        /**
         * For future use! Actual balance with quality check
         */
        final?: {
          checked: boolean;
          balance: number | null;
        };
      }
    >;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/reconciliation/actual-balance',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        accounts: accounts,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get accounts for the reconciliation view for a financial year
   * @returns any Reconciliation Accounts
   * @throws ApiError
   */
  public static getReconciliationAccounts({
    clientid,
    financialYearId,
  }: {
    clientid: string;
    financialYearId: number;
  }): CancelablePromise<
    Array<{
      /**
       * Account number
       */
      number: number;
      /**
       * Latest name of account
       */
      name: string;
      active: boolean;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/reconciliation/accounts',
      path: {
        clientid: clientid,
      },
      query: {
        financialYearId: financialYearId,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update client's picture
   * @returns any Picture updated successfully
   * @throws ApiError
   */
  public static putClientPicture({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Picture
     */
    requestBody: any;
  }): CancelablePromise<{
    logo: string;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/picture/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'image/jpeg',
      errors: {
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update period document reference
   * @returns void
   * @throws ApiError
   */
  public static updatePeriodDocumentReference({
    clientid,
    periodId,
    documentId,
    accountNumber,
  }: {
    clientid: string;
    periodId: number;
    documentId: number;
    accountNumber: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/period-documents/reference/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        documentId: documentId,
        accountNumber: accountNumber,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete period document reference
   * @returns void
   * @throws ApiError
   */
  public static deletePeriodDocumentReference({
    clientid,
    documentId,
    accountNumber,
    accountGroup,
    periodId,
  }: {
    clientid: string;
    documentId: number;
    accountNumber: string;
    accountGroup: string;
    periodId: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/period-documents/reference/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
        accountNumber: accountNumber,
        accountGroup: accountGroup,
        periodId: periodId,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create PDF and upload to period document
   * @returns any Period document stored successfully
   * @throws ApiError
   */
  public static printPeriodDocument({
    clientid,
    periodId,
    name,
    docType,
    account,
    requestBody,
  }: {
    clientid: string;
    periodId: number;
    name: string;
    docType: string;
    account: string;
    /**
     * Print document data
     */
    requestBody: {
      footer: string;
      header: string;
      parts: Array<string>;
      printState: any;
    };
  }): CancelablePromise<{
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/period-documents/print/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        name: name,
        docType: docType,
        account: account,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `File not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Copy period document
   * @returns any Period document stored successfully
   * @throws ApiError
   */
  public static copyPeriodDocument({
    clientid,
    documentId,
    newPeriodId,
    name,
    newAccount,
    newAccountGroup,
  }: {
    clientid: string;
    documentId: number;
    newPeriodId: number;
    name: string;
    /**
     * Either newAccount or newAccountGroup must be used
     */
    newAccount?: string;
    /**
     * Either newAccount or newAccountGroup must be used
     */
    newAccountGroup?: string;
  }): CancelablePromise<{
    id: number;
    name: string;
    url: string;
    lastModified: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/period-documents/copy/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
        newAccount: newAccount,
        newAccountGroup: newAccountGroup,
        newPeriodId: newPeriodId,
        name: name,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `File not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get period documents
   * @returns any OK
   * @throws ApiError
   */
  public static getPeriodDocuments({
    clientid,
    periodId,
    account,
    accountGroups,
  }: {
    clientid: string;
    periodId: Array<number>;
    account?: Array<string>;
    accountGroups?: Array<string>;
  }): CancelablePromise<
    Array<{
      id: number;
      name: string;
      url: string;
      lastModified: string;
      references: Array<{
        type: 'A' | 'G';
        /**
         * For accounts it's the account number, for groups it's the group key.
         */
        key: string;
        periodIds: Array<number>;
      }>;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/period-documents/',
      path: {
        clientid: clientid,
      },
      query: {
        account: account,
        accountGroups: accountGroups,
        periodId: periodId,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Upload period document
   * @returns any Period document copied successfully
   * @throws ApiError
   */
  public static postPeriodDocument({
    clientid,
    name,
    periodId,
    requestBody,
    account,
    accountGroup,
  }: {
    clientid: string;
    name: string;
    periodId: number;
    /**
     * Period document
     */
    requestBody: any;
    account?: string;
    accountGroup?: string;
  }): CancelablePromise<{
    id: number;
    name: string;
    url: string;
    lastModified: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/period-documents/',
      path: {
        clientid: clientid,
      },
      query: {
        account: account,
        accountGroup: accountGroup,
        name: name,
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/octet-stream',
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete period document
   * @returns void
   * @throws ApiError
   */
  public static deletePeriodDocument({
    clientid,
    documentId,
    periodId,
  }: {
    clientid: string;
    documentId: number;
    periodId: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/period-documents/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
        periodId: periodId,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Rename period document
   * @returns any Period document renamed successfully
   * @throws ApiError
   */
  public static renamePeriodDocument({
    clientid,
    documentId,
    newName,
    periodId,
  }: {
    clientid: string;
    documentId: number;
    newName: string;
    periodId: number;
  }): CancelablePromise<{
    id: number;
    name: string;
    url: string;
    lastModified: string;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/clients/{clientid}/period-documents/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
        newName: newName,
        periodId: periodId,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `File not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Put lamps
   * @returns void
   * @throws ApiError
   */
  public static putClientsLamps({
    clientid,
    requestBody,
  }: {
    clientid: string;
    requestBody: Array<PeriodLamps>;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/lamps/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid ID`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create a voucher in fortnox
   * @returns void
   * @throws ApiError
   */
  public static createVoucherFortnoxIntegration({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Voucher data
     */
    requestBody: {
      series?: string;
      transactionDate: string;
      description: string;
      rows: Array<{
        account: number;
        debit: number;
        credit: number;
        transactionInformation?: string;
      }>;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/integrations/fortnox/voucher/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update the stored sie file for a year, by fetching from fortnox
   * @returns any Ok
   * @throws ApiError
   */
  public static updateSieFortnoxIntegration({
    clientid,
    financialYear,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    financialYear: string;
  }): CancelablePromise<{
    updatedFinancialYears: Array<string> | null;
    /**
     * The jobId for the sie-update import to RDS
     */
    jobId?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/integrations/fortnox/update-sie/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        403: `Not authorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get file from fortnox
   * @returns any return file
   * @throws ApiError
   */
  public static getFortnoxFile({
    clientid,
    fileId,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    fileId: string;
  }): CancelablePromise<{
    fileName?: string;
    fileContent?: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/integrations/fortnox/file/',
      path: {
        clientid: clientid,
      },
      query: {
        fileId: fileId,
      },
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Fetch the status the fortnox integration for a client
   * @returns any Integration status
   * @throws ApiError
   */
  public static getFortnoxIntegrationStatus({
    clientid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
  }): CancelablePromise<{
    isActive: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/integrations/fortnox-oauth/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Start OAuth integration flow with Fortnox, for an Agoy client
   * @returns any Integration started
   * @throws ApiError
   */
  public static startFortnoxIntegration({
    clientid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
  }): CancelablePromise<{
    /**
     * redirect url to the Fortnox OAuth integration provider
     */
    integrationUrl: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/integrations/fortnox-oauth/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Deletes the fortnox integration for a client
   * @returns void
   * @throws ApiError
   */
  public static deleteFortnoxIntegration({
    clientid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/integrations/fortnox-oauth/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get saldo change history
   * @returns any OK
   * @throws ApiError
   */
  public static getSaldoChangeHistory({
    clientid,
    periodId,
    accounts,
    limit,
  }: {
    clientid: string;
    /**
     * Period ID
     */
    periodId: number;
    accounts: Array<number>;
    limit: number;
  }): CancelablePromise<
    Array<{
      account: number;
      periodId: number;
      saldo: number | null;
      createdAt: string;
      author: {
        id: string;
        fullName: string;
        email: string;
      };
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/hidden-row-input/saldo-change-history/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        accounts: accounts,
        limit: limit,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get checked change history
   * @returns any OK
   * @throws ApiError
   */
  public static getCheckedChangeHistory({
    clientid,
    period,
    accounts,
    limit,
  }: {
    clientid: string;
    /**
     * Period in format yyyymm, e.g. 201901
     */
    period: string;
    accounts: Array<string>;
    limit: number;
  }): CancelablePromise<
    Array<{
      checked: boolean;
      date: string;
      authorName: string;
      account: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/hidden-row-input/checked-change-history/',
      path: {
        clientid: clientid,
      },
      query: {
        period: period,
        accounts: accounts,
        limit: limit,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Put hidden row input data on DynamoDB
   * @returns void
   * @throws ApiError
   */
  public static putHiddenRowInput({
    clientid,
    accountingYear,
    year,
    month,
    periodId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    accountingYear: string;
    year: string;
    month: string;
    periodId: number;
    requestBody: Record<
      string,
      {
        visited: boolean;
        legacySpecifications: Array<{
          description: string;
          reference: string;
          account: string;
          amount: string | number;
          id?: number;
        }>;
        saldo: string | number;
        comment: string;
        printComment: boolean;
        checked: boolean;
        inventoryView: any;
        cashView: any;
        numImages: number;
      }
    >;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/hidden-row-input/',
      path: {
        clientid: clientid,
      },
      query: {
        accountingYear: accountingYear,
        year: year,
        month: month,
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * get financial Report for client
   * @returns any Ok
   * @throws ApiError
   */
  public static getFinancialReport({
    clientid,
    periodId,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Period id
     */
    periodId: number;
  }): CancelablePromise<
    | FinancialReportChanges
    | {
        changesId: number;
        changes: FinancialReportChanges;
      }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/financial-report/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Not authorized`,
        404: `Couldn't find the report`,
        500: `Server error`,
      },
    });
  }

  /**
   * update financial report for client
   * @returns void
   * @throws ApiError
   */
  public static updateFinancialReport({
    clientid,
    periodId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Period id
     */
    periodId: number;
    requestBody: {
      settings?: any;
      frontPage?: any;
      diagramRRBR?: any;
      tableRRBR?: any;
      cashFlowDiagram?: any;
      tableOfContents?: any;
      balanceSheet?: any;
      balanceSheetAssets?: any;
      incomeStatement?: any;
      incomeStatementTables?: any;
      documents?: any;
      content?: any;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/financial-report/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Not found`,
        409: `Document locked`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get file from S3
   * @returns any File
   * @throws ApiError
   */
  public static getFile({
    clientid,
    fileid,
  }: {
    clientid: string;
    fileid: number;
  }): CancelablePromise<{
    id: number;
    url: string;
    name: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/files/{fileid}/',
      path: {
        clientid: clientid,
        fileid: fileid,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete file from S3
   * @returns void
   * @throws ApiError
   */
  public static deleteFile({
    clientid,
    fileid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    fileid: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/files/{fileid}/',
      path: {
        clientid: clientid,
        fileid: fileid,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get files from S3
   * @returns any Files
   * @throws ApiError
   */
  public static getFilesByKey({
    clientid,
    periodId,
    key,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    key: DocumentReference;
  }): CancelablePromise<{
    files: Array<{
      id: number;
      name: string;
      url: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/files/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        key: key,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Put file on S3
   * @returns any File saved successfully
   * @throws ApiError
   */
  public static putFile({
    clientid,
    periodId,
    key,
    name,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    periodId: number;
    key: DocumentReference;
    name: string;
    /**
     * Document
     */
    requestBody: any;
  }): CancelablePromise<{
    id: number;
    url: string;
    name: string;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/files/',
      path: {
        clientid: clientid,
      },
      query: {
        periodId: periodId,
        key: key,
        name: name,
      },
      body: requestBody,
      mediaType: 'application/octet-stream',
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Import ink1 client documents
   * @returns any Document imported successfully
   * @throws ApiError
   */
  public static importInk1ClientDocument({
    clientid,
    year,
    requestBody,
  }: {
    /**
     * UUID of the client person
     */
    clientid: string;
    year: string;
    /**
     * Document
     */
    requestBody: any;
  }): CancelablePromise<{
    sruCreated: boolean;
    ink1Created: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/documents/import/ink1/',
      path: {
        clientid: clientid,
      },
      query: {
        year: year,
      },
      body: requestBody,
      mediaType: 'application/pdf',
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get client documents from S3
   * @returns any Documents
   * @throws ApiError
   */
  public static getClientDocuments({
    clientid,
    year,
    filterBy,
    category,
  }: {
    clientid: string;
    year: string;
    filterBy?: string;
    category?: string;
  }): CancelablePromise<{
    listDocuments: Array<{
      category: string;
      name: string;
      url: string;
      lastModified: string;
      __typename: 'Document';
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/documents/',
      path: {
        clientid: clientid,
      },
      query: {
        year: year,
        filterBy: filterBy,
        category: category,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Put documents on S3
   * @returns any Document added successfully
   * @throws ApiError
   */
  public static putClientDocument({
    clientid,
    year,
    category,
    name,
    program,
    section,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    year: string;
    category: string;
    name: string;
    program:
      | 'SYSTEM'
      | 'RECONCILIATION'
      | 'FIN_STATEMENT'
      | 'AN_REPORT'
      | 'TAX_DECLARATION'
      | 'FIN_STATEMENT_REPORT';
    section: string;
    /**
     * Document
     */
    requestBody: any;
  }): CancelablePromise<{
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/documents/',
      path: {
        clientid: clientid,
      },
      query: {
        year: year,
        category: category,
        name: name,
        program: program,
        section: section,
      },
      body: requestBody,
      mediaType: 'application/octet-stream',
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete documents from S3
   * @returns void
   * @throws ApiError
   */
  public static deleteClientDocument({
    clientid,
    year,
    category,
    name,
    program,
    section,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    year: string;
    category: string;
    name: string;
    program:
      | 'SYSTEM'
      | 'RECONCILIATION'
      | 'FIN_STATEMENT'
      | 'AN_REPORT'
      | 'TAX_DECLARATION'
      | 'FIN_STATEMENT_REPORT';
    section: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/documents/',
      path: {
        clientid: clientid,
      },
      query: {
        year: year,
        category: category,
        name: name,
        program: program,
        section: section,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Rename documents in S3
   * @returns any Document renamed successfully
   * @throws ApiError
   */
  public static renameClientDocument({
    clientid,
    year,
    category,
    oldName,
    newName,
    program,
    section,
  }: {
    clientid: string;
    year: string;
    category: string;
    oldName: string;
    newName: string;
    program:
      | 'SYSTEM'
      | 'RECONCILIATION'
      | 'FIN_STATEMENT'
      | 'AN_REPORT'
      | 'TAX_DECLARATION'
      | 'FIN_STATEMENT_REPORT';
    section: string;
  }): CancelablePromise<{
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/clients/{clientid}/documents/',
      path: {
        clientid: clientid,
      },
      query: {
        year: year,
        category: category,
        oldName: oldName,
        newName: newName,
        program: program,
        section: section,
      },
      errors: {
        400: `Validation error`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `File not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Send invoice report
   * @returns any OK
   * @throws ApiError
   */
  public static invoiceReport({
    clientid,
    requestBody,
  }: {
    clientid: string;
    requestBody: {
      date: string;
      report: string;
    };
  }): CancelablePromise<{
    url: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/customer-invoices/report/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Server error`,
      },
    });
  }

  /**
   * Send SQS eventto update customer invoices in DB use lambda
   * @returns void
   * @throws ApiError
   */
  public static sendSqsCustomerInvoicesEvent({
    clientid,
    invoicesType = 'customer',
  }: {
    clientid: string;
    invoicesType?: 'customer' | 'both';
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/customer-invoices/lambda/',
      path: {
        clientid: clientid,
      },
      query: {
        invoicesType: invoicesType,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get customer invoice attachment
   * @returns any Attachment
   * @throws ApiError
   */
  public static getCustomerInvoiceAttachment({
    clientid,
    invoiceid,
  }: {
    clientid: string;
    invoiceid: string;
  }): CancelablePromise<{
    fileContent: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/customer-invoices/{invoiceid}/attachment/',
      path: {
        clientid: clientid,
        invoiceid: invoiceid,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get list of customer invoices
   * @returns any List of customer invoices
   * @throws ApiError
   */
  public static getCustomerInvoices({
    clientid,
    date,
  }: {
    clientid: string;
    /**
     * End of month in YYYY-MM-DD format
     */
    date: Array<string>;
  }): CancelablePromise<{
    statusList: Array<string>;
    invoices: Array<{
      id: number;
      voucherId: number | null;
      clientId: string;
      invoiceNumber: number;
      customerNumber: string;
      customerName: string;
      total: number;
      balance: number;
      currency: string;
      currencyRate: number;
      date: string;
      dueAt: string;
      paidAt: string | null;
      ocr: string;
      status: string;
      source: string;
    }>;
    reportData: {
      totalInvoices: number;
      totalSumSEK: number;
      sumSEK: number;
      updateDate?: string;
      updateState?: 'completed' | 'running' | 'invoices_fail' | 'payments_fail';
      sums: Record<string, number>;
      customerInvoices: Array<{
        customerName: string;
        customerNumber: string;
        amount: number;
        totalSumSEK: number;
        invoices: Array<{
          invoiceNumber: number;
          date: string;
          dueAt: string;
          currency: string;
          total: number;
          totalSEK: number;
          saldo: number;
          saldoSEK: number;
        }>;
      }>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/customer-invoices/',
      path: {
        clientid: clientid,
      },
      query: {
        date: date,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Persist in DB fortnox customer invoices
   * @returns void
   * @throws ApiError
   */
  public static putCustomerInvoices({
    clientid,
    year,
    requestBody,
  }: {
    clientid: string;
    year: string;
    requestBody: {
      action: 'update-db';
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/customer-invoices/',
      path: {
        clientid: clientid,
      },
      query: {
        year: year,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Gets the custom account group object for a given financial year
   * @returns any fetched the custom account groups for a given financial year
   * @throws ApiError
   */
  public static getCustomAccountGroups({
    clientid,
    financialYearId,
  }: {
    /**
     * The id of the client
     */
    clientid: string;
    /**
     * The id of the financial year
     */
    financialYearId?: number;
  }): CancelablePromise<
    Array<{
      id: number;
      periodId: number;
      financialYearId: number;
      modifiedGroups: Array<{
        groupId: string;
        accountIds: Array<number>;
        updatedAt: string;
        updatedBy?: string;
      }>;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/customAccountGroup/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYearId: financialYearId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Creates a custom account group object
   * @returns any customAccountGroup created
   * @throws ApiError
   */
  public static createCustomAccountGroup({
    clientid,
    financialYearId,
    periodId,
    requestBody,
  }: {
    /**
     * The id of the client
     */
    clientid: string;
    /**
     * The id of the financial year
     */
    financialYearId: number;
    /**
     * The id of the period
     */
    periodId: number;
    requestBody: {
      modifiedGroups: Array<{
        groupId: string;
        accountIds: Array<number>;
      }>;
    };
  }): CancelablePromise<{
    id: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/customAccountGroup/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYearId: financialYearId,
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * deletes a custom account group object
   * @returns void
   * @throws ApiError
   */
  public static deleteCustomAccountGroup({
    clientid,
    financialYearId,
    periodId,
  }: {
    /**
     * The id of the client
     */
    clientid: string;
    /**
     * The id of the financial year
     */
    financialYearId: number;
    /**
     * The id of the period
     */
    periodId: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/customAccountGroup/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYearId: financialYearId,
        periodId: periodId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * update a custom account group object
   * @returns any customAccountGroup updated
   * @throws ApiError
   */
  public static updateCustomAccountGroup({
    clientid,
    financialYearId,
    periodId,
    requestBody,
  }: {
    /**
     * The id of the client
     */
    clientid: string;
    /**
     * The id of the financial year
     */
    financialYearId: number;
    /**
     * The id of the period
     */
    periodId: number;
    requestBody: {
      modifiedGroups: Array<{
        groupId: string;
        accountIds: Array<number>;
      }>;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/clients/{clientid}/customAccountGroup/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYearId: financialYearId,
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * get token from cling
   * @returns any A token response from cling
   * @throws ApiError
   */
  public static getClingToken({
    clientid,
  }: {
    clientid: string;
  }): CancelablePromise<{
    authToken: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/cling/token/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `bad request`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Calculate the statuses for each signee for cling.
   * @returns any Calculated responses from cling
   * @throws ApiError
   */
  public static getClingStatuses({
    clientid,
    documentId,
  }: {
    clientid: string;
    documentId: string;
  }): CancelablePromise<{
    documentStatus: string;
    clientStatuses: Array<{
      name: string;
      email?: string;
      signingStatus: SigningStatus;
      date: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/cling/status/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
      },
      errors: {
        400: `bad request`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add a clingDocumentHistory object.
   * @returns any Created
   * @throws ApiError
   */
  public static addClingDocumentHistory({
    clientid,
    documentId,
    clingDocumentId,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * UUID of the document
     */
    documentId: string;
    /**
     * Id of cling document
     */
    clingDocumentId: string;
    requestBody: {
      status:
        | 'expired'
        | 'sent'
        | 're_sent'
        | 'declined'
        | 'draft'
        | 'accepted'
        | 'denied'
        | 'voided';
    };
  }): CancelablePromise<{
    id: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/cling/documentHistory/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
        clingDocumentId: clingDocumentId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Organisation not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * gets the document history for a client for a certain document.
   * @returns any Success
   * @throws ApiError
   */
  public static getClingDocumentHistory({
    clientid,
    documentId,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * UUID of the document
     */
    documentId: string;
  }): CancelablePromise<
    Array<{
      id: number;
      status:
        | 'expired'
        | 'sent'
        | 're_sent'
        | 'declined'
        | 'draft'
        | 'accepted'
        | 'denied'
        | 'voided';
      createdAt: string;
      updatedAt: string;
      clientId: string;
      documentId: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/cling/documentHistory/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * fetches a cling document under signing.
   * @returns string OK
   * @throws ApiError
   */
  public static getClingDocumentPdf({
    clientid,
    documentId,
    financialYear,
    name,
  }: {
    /**
     * The client id
     */
    clientid: string;
    /**
     * The document id
     */
    documentId: string;
    /**
     * The financialYear
     */
    financialYear: string;
    /**
     * The document name
     */
    name: string;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/cling/document/{documentId}/',
      path: {
        clientid: clientid,
        documentId: documentId,
      },
      query: {
        financialYear: financialYear,
        name: name,
      },
      errors: {
        400: `bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * fetches a cling document under signing.
   * @returns any OK
   * @throws ApiError
   */
  public static getClingDocument({
    clientid,
    documentId,
  }: {
    /**
     * The client id
     */
    clientid: string;
    /**
     * The document id
     */
    documentId: string;
  }): CancelablePromise<{
    id: string;
    clients: Array<{
      name: string;
      email?: string;
      answer: {
        didAccept: boolean;
        didAnswerAt: string;
      } | null;
      signOrder: number;
    }>;
    data?: {
      name?: string;
      rejectReasons?: Array<{
        category: string;
        description: string;
        key: string;
        sentAt: string;
      }>;
    };
    status: string;
    events: Array<
      | {
          code: 'viewed';
          createdAt: string;
          data: {
            client: {
              name: string;
            };
          };
        }
      | {
          code: 'denied';
          createdAt: string;
          data: {
            client: {
              name: string;
            };
          };
        }
      | {
          code: 'statusChanged';
          createdAt: string;
          data: {
            status:
              | 'draft'
              | 'sent'
              | 'accepted'
              | 'denied'
              | 'expired'
              | 'voided';
          };
        }
      | {
          code: 'expired';
          createdAt: string;
          data: {
            client: {
              email: string;
            };
          } | null;
        }
      | {
          code:
            | 'mailDelivered'
            | 'mailOpened'
            | 'mailDropped'
            | 'smsDropped'
            | 'requestChange'
            | 'sentRequestPropertyDesignation'
            | 'accepted'
            | 'sent';
          createdAt: string;
        }
    >;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/cling/document/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
      },
      errors: {
        400: `bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Cling document not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * delete the cling document
   * @returns void
   * @throws ApiError
   */
  public static deleteClingSigning({
    clientid,
    documentId,
  }: {
    /**
     * The client id
     */
    clientid: string;
    /**
     * The document id
     */
    documentId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/cling/document/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
      },
      errors: {
        400: `bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Cling document not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Cancel signing process
   * @returns any OK
   * @throws ApiError
   */
  public static cancelClingSigning({
    clientid,
    documentId,
  }: {
    /**
     * The client id
     */
    clientid: string;
    /**
     * The document id
     */
    documentId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/clients/{clientid}/cling/document/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
      },
      errors: {
        400: `bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Cling document not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Restarts the signing process of an ongoing document.
   * @returns any OK
   * @throws ApiError
   */
  public static resendClingDocument({
    clientid,
    documentId,
  }: {
    /**
     * The client id
     */
    clientid: string;
    /**
     * The document id
     */
    documentId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/cling/document/',
      path: {
        clientid: clientid,
      },
      query: {
        documentId: documentId,
      },
      errors: {
        400: `bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Cling document not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create relations between client and checklist.
   * @returns any Check list assigned
   * @throws ApiError
   */
  public static assignChecklistToClient({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * these are the checklist attributes required for creation.
     */
    requestBody: {
      templateId: number;
      financialYearId: number;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/clientChecklistRelations/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Fetch the client information of the client
   * @returns ClientInformation Client Information data
   * @throws ApiError
   */
  public static getClientInformation({
    clientid,
  }: {
    /**
     * The id of the client
     */
    clientid: string;
  }): CancelablePromise<ClientInformation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/client-information/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Patch the client information of the client
   * @returns any Updated client information
   * @throws ApiError
   */
  public static patchClientInformation({
    clientid,
    source,
    requestBody,
  }: {
    /**
     * The id of the client
     */
    clientid: string;
    /**
     * source of data (user or cs)
     */
    source: string;
    requestBody: ClientInformationUpdate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/client-information/',
      path: {
        clientid: clientid,
      },
      query: {
        source: source,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        403: `Not authorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get statuses for client by financialYearId
   * @returns any Statuses
   * @throws ApiError
   */
  public static getCheckListItemStatus({
    clientid,
    financialYearId,
  }: {
    clientid: string;
    financialYearId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/checkListItemStatus/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYearId: financialYearId,
      },
      errors: {
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set question status.
   * @returns any Status updated
   * @throws ApiError
   */
  public static setCheckListItemStatus({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * these are the checklist attributes required for creation.
     */
    requestBody: Array<{
      financialYearId: number;
      status: 'in_progress' | 'yes' | 'no' | 'not_started' | 'not_applicable';
      clientId: string;
      questionId: number;
      periodId: number;
      id?: number;
    }>;
  }): CancelablePromise<{
    status: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/checkListItemStatus/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update client checklist print state
   * @returns void
   * @throws ApiError
   */
  public static setClientChecklistPrintState({
    clientid,
    templateId,
    financialYearId,
    requestBody,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    templateId: number;
    financialYearId: number;
    /**
     * State to update
     */
    requestBody: {
      includeInPrint: boolean;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/clients/{clientid}/checklist/',
      path: {
        clientid: clientid,
      },
      query: {
        templateId: templateId,
        financialYearId: financialYearId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Checklist not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Validate submission to Bolagsverket
   * @returns any OK
   * @throws ApiError
   */
  public static bolagsverketValidateSubmission({
    clientid,
    financialYear,
    requestBody,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
    requestBody: {
      annualReport: SharesCompanyAnnualReport;
      confirmationCertificate: any;
      documentConfiguration: DocumentConfiguration;
    };
  }): CancelablePromise<
    Array<{
      kod: string;
      text: string;
      typ: string;
      tekniskinformation?: Array<{
        meddelande?: string | null;
        element?: string | null;
        varde?: string | null;
      }> | null;
    }>
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/annualReport/{financialYear}/submit/validate',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        403: `Not authenticated`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Submit annual report to Bolagsverket
   * @returns any OK
   * @throws ApiError
   */
  public static bolagsverketSubmitSubmission({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
  }): CancelablePromise<{
    updatedAt?: string;
    state: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/annualReport/{financialYear}/submit/submit',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get status of BV submission
   * @returns any OK
   * @throws ApiError
   */
  public static bolagsverketGetSubmissionStatusForClient({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
  }): CancelablePromise<
    Array<
      | {
          bvStatus?:
            | 'arsred_inkommen'
            | 'arsred_registrerad'
            | 'arsred_avslutad_ej_registrerad'
            | 'arsred_forelaggande_skickat'
            | 'arsred_komplettering_inkommen';
          updatedAt: string;
          state: 'initiated' | 'errors' | 'valid' | 'submitted';
          userId?: string;
        }
      | {
          updatedAt: string;
          state: 'agreement_required';
          agreementText: string;
        }
      | {
          state: 'not_initiated';
        }
    >
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/annualReport/{financialYear}/submit/status/getStatusForClient',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get status of BV submission
   * @returns BVSubmissionState OK
   * @throws ApiError
   */
  public static bolagsverketGetStatus({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
  }): CancelablePromise<BVSubmissionState> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/annualReport/{financialYear}/submit/status',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Initiate submission to Bolagsverket
   * @returns any OK
   * @throws ApiError
   */
  public static bolagsverketInitiateSubmission({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
  }): CancelablePromise<{
    state: string;
    agreementText?: string;
    updatedAt?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/annualReport/{financialYear}/submit/initiate',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Agree to Bolagsverkets terms
   * @returns BVSubmissionState OK
   * @throws ApiError
   */
  public static bolagsverketAgreeSubmission({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID, Example: 3d6263ea-aced-40be-bc8f-f5758cd5fa45
     */
    clientid: string;
    /**
     * Financial year, Example: 201901-201912
     */
    financialYear: string;
  }): CancelablePromise<BVSubmissionState> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/annualReport/{financialYear}/submit/agree',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get the annual report signature value
   * @returns AnnualReportSignature OK
   * @throws ApiError
   */
  public static getAnnualReportSignature({
    clientid,
    financialYear,
    documentType,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
    /**
     * Document type
     */
    documentType: 'annualReport' | 'annualGeneralMeeting';
  }): CancelablePromise<AnnualReportSignature> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/annualReport/{financialYear}/signature/',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      query: {
        documentType: documentType,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set or reset the annual report signature value
   * @returns AnnualReportSignature OK
   * @throws ApiError
   */
  public static setAnnualReportSignature({
    clientid,
    financialYear,
    requestBody,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
    requestBody: {
      signature: boolean;
      documentType: 'annualReport' | 'annualGeneralMeeting';
    };
  }): CancelablePromise<AnnualReportSignature> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{clientid}/annualReport/{financialYear}/signature/',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * fetches an array of history objects for a given client and financial year. The history objects are sorted by createdAt date in descending order. The history objects are created when the annual report is signed, submitted to BV and when the BV status changes.
   * @returns any OK
   * @throws ApiError
   */
  public static getAnnualReportHistory({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
  }): CancelablePromise<
    Array<{
      clientId: string;
      userId: string;
      event:
        | 'certificate_signed'
        | 'BV_status_changed'
        | 'submitted_to_BV'
        | 'unlocked'
        | 'awaiting_signature';
      financialYear: string;
      createdAt: string;
      bvStatus?:
        | 'arsred_inkommen'
        | 'arsred_registrerad'
        | 'arsred_avslutad_ej_registrerad'
        | 'arsred_forelaggande_skickat'
        | 'arsred_komplettering_inkommen';
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/annualReport/{financialYear}/history/',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get the entire annual report with the changes already applied and values resolved. This is what we need for Simplified Annual Report.
   * @returns AnnualReport OK
   * @throws ApiError
   */
  public static getFullAnnualReport({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
  }): CancelablePromise<AnnualReport> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/annualReport/{financialYear}/full/',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get signatures for annual report document
   * @returns any OK
   * @throws ApiError
   */
  public static getAnnualReportDocumentSignatures({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
  }): CancelablePromise<{
    id: string;
    clientId: string;
    financialYearId: number | null;
    periodId: number | null;
    changesId: number | null;
    updatedAt: string;
    name: string | null;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/annualReport/{financialYear}/document/signatures/',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Initiate submission to Bolagsverket
   * @returns any OK
   * @throws ApiError
   */
  public static getAnnualReportChanges({
    clientid,
    financialYear,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
  }): CancelablePromise<{
    changesId: number | null;
    documentId: string;
    changes: AnnualReportChanges;
    locked: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/annualReport/{financialYear}/changes',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update changes in Annual report
   * @returns void
   * @throws ApiError
   */
  public static putAnnualReportChanges({
    clientid,
    financialYear,
    requestBody,
  }: {
    /**
     * Client ID
     */
    clientid: string;
    /**
     * Financial year
     */
    financialYear: string;
    requestBody: AnnualReportChanges;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/annualReport/{financialYear}/changes',
      path: {
        clientid: clientid,
        financialYear: financialYear,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        402: `Requires payment`,
        403: `Unauthorized`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get vouchers and transactions
   * @returns any Vouchers response
   * @throws ApiError
   */
  public static getVouchers({
    clientid,
    account,
    offset,
    limit = 20,
    includeTransactions = false,
    financialYearId,
    financialYear,
  }: {
    clientid: string;
    account?: string;
    offset?: number;
    limit?: number;
    includeTransactions?: boolean;
    financialYearId?: number;
    financialYear?: string;
  }): CancelablePromise<
    {
      pagination: {
        totalResults: number;
        totalPages: number;
        currentPage: number;
      };
      periods?: Array<AccountingPeriod>;
    } & (
      | {
          sourceType?: 'file';
          vouchers?: Array<SIEVoucher>;
        }
      | {
          sourceType?: 'agoy';
          vouchers?: Array<Voucher>;
        }
    )
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/accounting/vouchers',
      path: {
        clientid: clientid,
      },
      query: {
        account: account,
        offset: offset,
        limit: limit,
        includeTransactions: includeTransactions,
        financialYearId: financialYearId,
        financialYear: financialYear,
      },
      errors: {
        304: `Not modified`,
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get transactions
   * @returns any Transactions response
   * @throws ApiError
   */
  public static getTransactions({
    clientid,
    accountNumber,
    offset,
    limit = 20,
    periodId,
    voucherId,
  }: {
    clientid: string;
    accountNumber?: string;
    offset?: number;
    limit?: number;
    periodId?: Array<number>;
    voucherId?: number;
  }): CancelablePromise<{
    pagination: {
      totalResults: number;
      totalPages: number;
      currentPage: number;
    };
    transactions: Array<{
      id: number;
      index: number;
      credit: number;
      debit: number;
      date: string;
      accountNumber: number;
      text: string | null;
      voucher: {
        id?: number;
        series: string;
        number: number;
        description: string | null;
        regdate: string | null;
      };
      objects: Array<{
        name: string;
        no: string;
      }> | null;
      balance: number;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/accounting/transactions',
      path: {
        clientid: clientid,
      },
      query: {
        accountNumber: accountNumber,
        offset: offset,
        limit: limit,
        periodId: periodId,
        voucherId: voucherId,
      },
      errors: {
        304: `Not modified`,
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get account balances for a financial year
   * @returns any Accounting balances response
   * @throws ApiError
   */
  public static getAccountingBalances({
    clientid,
    financialYearId,
    financialYear,
    ifModifiedSince,
  }: {
    clientid: string;
    financialYearId?: number;
    financialYear?: string;
    ifModifiedSince?: string;
  }): CancelablePromise<
    | {
        sourceType: 'file';
        metadata: {
          financialYearStart: string;
          financialYearEnd: string;
          clientName: string;
          organisationNumber: string;
          program: string;
          createdAt: string;
          dateOfLastVoucher: string | null;
        };
        financialYears: Array<FinancialYear>;
        periods: Array<AccountingPeriod>;
        accounts: Array<AccountingAccount>;
        lastModified?: string;
      }
    | {
        sourceType: 'agoy';
        financialYears: Array<FinancialYear>;
        periods: Array<AccountingPeriod>;
        accounts: Array<AccountingAccount>;
        lastModified?: string;
      }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/accounting/balances',
      path: {
        clientid: clientid,
      },
      headers: {
        'if-modified-since': ifModifiedSince,
      },
      query: {
        financialYearId: financialYearId,
        financialYear: financialYear,
      },
      errors: {
        304: `Not modified`,
        400: `Incorrect parameters`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get customer transaction attachment
   * @returns any Attachment
   * @throws ApiError
   */
  public static getTransactionAttachment({
    clientid,
    voucher,
    voucherYear,
  }: {
    clientid: string;
    /**
     * The voucher series and number, like A-20
     */
    voucher: string;
    voucherYear: string;
  }): CancelablePromise<
    Array<{
      fileId?: string;
      fileName?: string;
      fileSize?: number;
      fileContent?: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/accounting/{voucher}/attachment',
      path: {
        clientid: clientid,
        voucher: voucher,
      },
      query: {
        voucherYear: voucherYear,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get client by clientId
   * @returns any Ok
   * @throws ApiError
   */
  public static getClientById({
    clientid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
  }): CancelablePromise<
    | {
        client: ClientCompany;
        messages: Array<{
          level: 'info' | 'warning' | 'error';
          id: string;
          details: any;
        }>;
      }
    | ClientCompany
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{clientid}/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't find client`,
        500: `Server error`,
      },
    });
  }

  /**
   * Update a client's data
   * @returns any Updated
   * @throws ApiError
   */
  public static updateClientById({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * Client data to update
     */
    requestBody: {
      name?: string | null;
      address?: string | null;
      orgNumber?: string | null;
      closingMonth?: number | null;
      closingPeriod?: string | null;
      email?: string | null;
      contact?: string | null;
      financialYears?: Array<string> | null;
      managerId?: string | null;
      type?:
        | 'shares'
        | 'limited'
        | 'limited_partnership'
        | 'individual'
        | 'economic_association'
        | 'non_profit_association'
        | 'foundation'
        | 'other'
        | null;
      active?: boolean | null;
      vatPeriod?: 'month' | 'quarter' | 'year' | 'novat' | null;
      startDate?: string | null;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{clientid}/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't find client`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete client
   * @returns void
   * @throws ApiError
   */
  public static deleteClientById({
    clientid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/clients/{clientid}/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't delete client`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get share owner relations
   * @returns any Ok
   * @throws ApiError
   */
  public static getShareOwnerRelations({
    clientid,
    financialYear,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientid: string;
    financialYear?: string;
  }): CancelablePromise<
    Array<{
      relationId: number;
      ownerType: string;
      ownerId?: string;
      ownerName: string;
      ownerNumber: string;
      companyType?: string;
      sharePercentage?: number;
      ownedShares?: number;
      logo?: string;
      visible?: boolean;
      hasMainBusiness?: boolean;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client-relations/{clientid}/owner/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        400: `Bad Request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * delete a client relation.
   * @returns void
   * @throws ApiError
   */
  public static deleteClientRelation({
    clientid,
    relationid,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientid: string;
    /**
     * the id of the relation you wish to remove.
     */
    relationid: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/client-relations/{clientid}/{relationid}/',
      path: {
        clientid: clientid,
        relationid: relationid,
      },
      errors: {
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Relation not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get client Relations
   * @returns any Ok
   * @throws ApiError
   */
  public static getClientRelations({
    clientid,
    startOf,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientid: string;
    /**
     * Optional, date to filter the relations on. 2022-01-01 will give the state at the beginning of that date.
     */
    startOf?: string;
  }): CancelablePromise<
    Array<{
      relationId: number;
      orgId: string;
      orgName: string;
      orgNumber: string;
      orgType: string;
      shareInformationId?: number;
      sharePercentage?: number;
      ownedShares?: number;
      stockTotals?: Array<{
        type: 'new_registration' | 'purchase' | 'new_emission' | 'other';
        date: string;
        comment?: string | null;
        id: number;
        clientCompanyId: string;
        shareTotal: number;
      }>;
      stockTransactions?: Array<{
        id: number;
        clientRelationId: number;
        shareChange: number;
        transactionDate: string;
        valuePerShare: number;
        comment?: string | null;
      }>;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client-relations/{clientid}/',
      path: {
        clientid: clientid,
      },
      query: {
        startOf: startOf,
      },
      errors: {
        400: `Bad Request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Adds a Client relation between a Client and a company for Stock/share information.
   * @returns any Created
   * @throws ApiError
   */
  public static addClientRelation({
    clientid,
    requestBody,
  }: {
    /**
     * id of the client, person or company doesnt matter.
     */
    clientid: string;
    requestBody: {
      relation: {
        fromClientId: string;
        toCompanyId: string;
      };
      stockTransaction?: {
        shareChange: number;
        valuePerShare: number;
        transactionDate: string;
        comment?: string;
      };
      stockTotal?: {
        clientCompanyId: string;
        shareTotal: number;
        type: 'new_registration' | 'purchase' | 'new_emission' | 'other';
        date: string;
        comment?: string | null;
      };
    };
  }): CancelablePromise<{
    id: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/client-relations/{clientid}/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Not authenticated`,
        401: `Not authenticated`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add a past financial year to a clientPerson up to 7 years back.
   * @returns any Past financial year added to clientPerson
   * @throws ApiError
   */
  public static addPastFinancialYear({
    clientid,
  }: {
    /**
     * UUID of the person
     */
    clientid: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/client-persons/{clientid}/pastFinancialYears/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Max year date reached (7 years back)`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete the oldest past financial year for a clientPerson.
   * @returns void
   * @throws ApiError
   */
  public static deletePastFinancialYear({
    clientid,
    financialYear,
  }: {
    /**
     * UUID of the person
     */
    clientid: string;
    /**
     * The financial year to be deleted
     */
    financialYear: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/client-persons/{clientid}/pastFinancialYears/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYear: financialYear,
      },
      errors: {
        400: `The current tax year cannot be deleted`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create relations between person and checklist.
   * @returns any Check list assigned
   * @throws ApiError
   */
  public static assignChecklistToPerson({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the person
     */
    clientid: string;
    /**
     * these are the checklist attributes required for creation.
     */
    requestBody: {
      templateId: number;
      financialYearId: number;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/client-persons/{clientid}/clientChecklistRelations/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get statuses for client by financialYearId
   * @returns any Statuses
   * @throws ApiError
   */
  public static getPersonCheckListItemStatus({
    clientid,
    financialYearId,
  }: {
    clientid: string;
    financialYearId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client-persons/{clientid}/checkListItemStatus/',
      path: {
        clientid: clientid,
      },
      query: {
        financialYearId: financialYearId,
      },
      errors: {
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set question status.
   * @returns any Status updated
   * @throws ApiError
   */
  public static setPersonCheckListItemStatus({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    /**
     * these are the checklist attributes required for creation.
     */
    requestBody: Array<{
      financialYearId: number;
      status: 'in_progress' | 'yes' | 'no' | 'not_started' | 'not_applicable';
      clientId: string;
      questionId: number;
      periodId?: number;
      id?: number;
    }>;
  }): CancelablePromise<{
    status: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/client-persons/{clientid}/checkListItemStatus/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Not authenticated`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get clientPerson by Id
   * @returns any Ok
   * @throws ApiError
   */
  public static getClientPersonById({
    clientid,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
  }): CancelablePromise<{
    id: string;
    taxAddress: {
      id: number;
      zipCode: string;
      street: string;
      city: string;
      additionalAddressField1?: string;
      additionalAddressField2?: string;
      country: string;
    };
    personNumber: string;
    contactPhoneNumber?: string;
    contactEmail: string;
    firstName: string;
    lastName: string;
    managerId: string | null;
    logo?: string;
    checkLists?: Array<{
      templateId?: number;
      financialYearId?: number;
      program?: string;
    }>;
    rawFinancialYears: Array<{
      id: number;
      start: string;
      end: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client-persons/{clientid}/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Couldn't find clientPerson`,
        500: `Server error`,
      },
    });
  }

  /**
   * update clientPerson
   * @returns void
   * @throws ApiError
   */
  public static updateClientPerson({
    clientid,
    requestBody,
  }: {
    /**
     * UUID of the client
     */
    clientid: string;
    requestBody: {
      taxAddress: {
        zipCode: string;
        street: string;
        city: string;
        additionalAddressField1?: string;
        additionalAddressField2?: string;
        country: string;
      };
      postAddress?: {
        zipCode: string;
        street: string;
        city: string;
        additionalAddressField1?: string;
        additionalAddressField2?: string;
        country: string;
      };
      clientPerson: {
        contactEmail: string;
        logo: string;
        managerId: string | null;
        firstName: string;
        lastName: string;
        contactPhoneNumber?: string;
        personNumber?: string;
        organisationId?: string;
      };
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/client-persons/{clientid}/',
      path: {
        clientid: clientid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Client Person not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete clientPerson
   * @returns void
   * @throws ApiError
   */
  public static deleteClientPersonById({
    clientid,
  }: {
    /**
     * UUID of the clientPerson
     */
    clientid: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/client-persons/{clientid}/',
      path: {
        clientid: clientid,
      },
      errors: {
        400: `Invalid Id`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `ClientPerson not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Gets values for a document
   * @returns any Document values
   * @throws ApiError
   */
  public static getAgoyDocumentValues({
    documentId,
    clientId,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
  }): CancelablePromise<{
    values: Record<string, string | number | null | boolean>;
    updatedAt: string;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/agoy-document/{clientId}/{documentId}/values/',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Saves status for a document
   * @returns void
   * @throws ApiError
   */
  public static setAgoyDocumentStatus({
    documentId,
    clientId,
    requestBody,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
    requestBody: {
      status: 'NOT_STARTED' | 'STARTED' | 'AW_COMPL' | 'AW_REVIEW' | 'DONE';
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/agoy-document/{clientId}/{documentId}/status/',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns the locking events for a document
   * @returns any Returns the stored locking events for a document
   * @throws ApiError
   */
  public static getLockingEvents({
    documentId,
    clientId,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
  }): CancelablePromise<
    Array<{
      clientId: string;
      userId: string;
      documentId: string;
      createdAt: string;
      locked: boolean;
      reason?: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/agoy-document/{clientId}/{documentId}/locking-events/',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Saves status for a document
   * @returns void
   * @throws ApiError
   */
  public static setAgoyDocumentDescription({
    documentId,
    clientId,
    requestBody,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
    requestBody: {
      description: string | null;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/agoy-document/{clientId}/{documentId}/description/',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Adds a connection between two documents
   * @returns void
   * @throws ApiError
   */
  public static addAgoyDocumentConnection({
    documentId,
    clientId,
    toDocumentId,
    requestBody,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
    toDocumentId: string;
    requestBody: {
      name: string | null;
      order: number | null;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/agoy-document/{clientId}/{documentId}/connections/{toDocumentId}/',
      path: {
        documentId: documentId,
        clientId: clientId,
        toDocumentId: toDocumentId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns the connections from a document
   * @returns any Returns the stored connections for a document
   * @throws ApiError
   */
  public static getAgoyDocumentConnections({
    documentId,
    clientId,
    type,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
    /**
     * Type of connection
     */
    type: 'uses' | 'subForm';
  }): CancelablePromise<{
    connections: Array<{
      toId: string;
      name: string | null;
      order: number | null;
      clientId: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/agoy-document/{clientId}/{documentId}/connections',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      query: {
        type: type,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns the latest changes for a document
   * @returns any Returns the stored changes for a document
   * @throws ApiError
   */
  public static getAgoyDocumentChanges({
    documentId,
    clientId,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
  }): CancelablePromise<{
    changesId: number | null;
    changes: any;
    locked: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/agoy-document/{clientId}/{documentId}/changes',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Saves changes for a document
   * @returns any Returns the stored changes for a document
   * @throws ApiError
   */
  public static addAgoyDocumentChanges({
    documentId,
    clientId,
    requestBody,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
    requestBody: any;
  }): CancelablePromise<{
    changesId: number;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/agoy-document/{clientId}/{documentId}/changes',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        409: `Document locked`,
        500: `Server error`,
      },
    });
  }

  /**
   * Saves changes for a document
   * @returns void
   * @throws ApiError
   */
  public static deleteAgoyDocument({
    documentId,
    clientId,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/agoy-document/{clientId}/{documentId}/',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Updates a document's information
   * @returns void
   * @throws ApiError
   */
  public static patchAgoyDocument({
    documentId,
    clientId,
    requestBody,
    reason,
  }: {
    /**
     * The id of the document
     */
    documentId: string;
    /**
     * The id of the client
     */
    clientId: string;
    requestBody: {
      locked?: boolean;
    };
    /**
     * reason for unlocking
     */
    reason?: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/agoy-document/{clientId}/{documentId}/',
      path: {
        documentId: documentId,
        clientId: clientId,
      },
      query: {
        reason: reason,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Saves changes for a document
   * @returns any Document created
   * @throws ApiError
   */
  public static createAgoyDocument({
    clientId,
    requestBody,
  }: {
    /**
     * The id of the client
     */
    clientId: string;
    requestBody: {
      type: string;
      financialYear?: string;
      period?: string;
      name: string | null;
      parentId?: string;
      description?: string;
      /**
       * Key-value mapping of connected clients where key is the name of relationship according to the document structure and value is the connected client's id.
       */
      relatedClients?: Record<string, string>;
    };
  }): CancelablePromise<{
    id: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/agoy-document/{clientId}/',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Saves changes for a document
   * @returns any Document created
   * @throws ApiError
   */
  public static findAgoyDocument({
    clientId,
    financialYear,
    type,
    relativeFinancialYear,
    relationType,
  }: {
    /**
     * The id of the client
     */
    clientId: string;
    financialYear: string;
    type: string;
    relativeFinancialYear?: number;
    relationType?: 'ownership' | 'sibling' | 'ownedByOwner';
  }): CancelablePromise<
    Array<{
      id: string;
      clientId: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/agoy-document/{clientId}/',
      path: {
        clientId: clientId,
      },
      query: {
        financialYear: financialYear,
        type: type,
        relativeFinancialYear: relativeFinancialYear,
        relationType: relationType,
      },
      errors: {
        400: `Bad request`,
        401: `Not authenticated`,
        403: `Unauthorized`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }
}
