import {
  configINK1,
  configINK2,
  configINK3,
  configINK4,
  configK2,
  configK4,
  configK5,
  configK6,
  configK7,
  configK8,
  configK9,
  configK10,
  configK11,
  configK12,
  configK13,
  configK15A,
  configK15B,
  configN4,
  configN7,
  configN8,
  configN9,
  configNEA,
  configNE,
  configN3A,
  configN3B,
  configT2,
  configFritextbrev,
  configOVR,
  configN11,
  configForenklatArsbokslut,
  configNEPerson,
  configNEPersonHelper,
  configCoverLetter,
} from './forms';
import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  CompanyMetaData,
  PersonMetaData,
} from './types';

type CompanyInput = {
  orgNumber?: string | null;
  name?: string | null;
  address?: string | null;
  contact?: string | null;
  number?: string | null;
  type?: string | null;
};

type PersonInput = {
  firstName?: string | null;
  lastName?: string | null;
  taxAddress: {
    street?: string | null;
    zipCode?: string | null;
    city?: string | null;
  };
  personNumber?: string | null;
};

/**
 * Generates a config object for the requested tax declaration form.
 *
 * @param form The form to get the config for
 * @param customer Could be either a company or person object, types are defined in web and I didn't want to move them here
 * @param financialYearStart format YYYYMMDD
 * @param financialYearEnd  format YYYYMMDD
 * @returns
 */
const getTaxDeclarationFormDataConfig = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyInput | PersonInput,
  financialYearStart: string,
  financialYearEnd: string,
  accountingFirm?: string | null
): TaxDeclarationFormDataConfig => {
  // forms valid for companies
  if ('orgNumber' in customer) {
    const companyMeta: CompanyMetaData = {
      orgNumber: customer.orgNumber ?? '',
      name: customer.name ?? '',
      address: customer.address ?? '',
      contactPerson: customer.contact ?? '',
      phoneNumber: customer.number ?? '',
      type: customer.type ?? '',
    };

    switch (form.type) {
      case 'SKV-2002':
        return configINK2(
          form,
          companyMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-2003':
        return configINK3(
          form,
          companyMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-2004':
        return configINK4(
          form,
          companyMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-2110':
        return configK10(form);
      case 'SKV-2161':
        return configNE(
          form,
          companyMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-2153':
        return configN3A(form, financialYearStart, financialYearEnd);
      case 'SKV-2167':
        return configN3B(form, financialYearStart, financialYearEnd);
      case 'SKV-2154':
        return configN4(form, financialYearStart, financialYearEnd);
      case 'SKV-2170':
        return configN7(form, financialYearStart, financialYearEnd);
      case 'SKV-2155':
        return configN8(form, financialYearStart, financialYearEnd);
      case 'SKV-2158':
        return configN9(form, financialYearStart, financialYearEnd);
      case 'SKV-2157':
        return configN11(form, financialYearStart, financialYearEnd);
      case 'SKV-2164':
        return configNEA(
          form,
          companyMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-FRI':
        return configFritextbrev(
          form,
          companyMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-CL':
        return configCoverLetter(
          form,
          companyMeta,
          accountingFirm,
          financialYearStart
        );
      case 'SKV-OVR':
        return configOVR(
          form,
          companyMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-2150':
        return configForenklatArsbokslut(
          form,
          companyMeta,
          financialYearStart,
          financialYearEnd
        );
      default:
        throw new Error(`No config for company ${form.type}`);
    }
  } else if ('personNumber' in customer) {
    // forms valid for private individuals
    const personMeta: PersonMetaData = {
      firstName: customer?.firstName ?? '',
      lastName: customer?.lastName ?? '',
      street: customer?.taxAddress.street ?? '',
      zipCode: customer?.taxAddress.zipCode ?? '',
      city: customer?.taxAddress.city ?? '',
      personNumber: customer?.personNumber ?? '',
    };

    switch (form.type) {
      case 'SKV-2001':
        return configINK1(form, personMeta, financialYearEnd);
      case 'SKV-2102':
        return configK2(form, personMeta, financialYearEnd);
      case 'SKV-2104':
        return configK4(form, personMeta);
      case 'SKV-2105':
        return configK5(form, personMeta, financialYearEnd);
      case 'SKV-2106':
        return configK6(form, personMeta, financialYearEnd);
      case 'SKV-2107':
        return configK7(form, personMeta);
      case 'SKV-2108':
        return configK8(form, personMeta);
      case 'SKV-2119':
        return configK9(form, personMeta, financialYearEnd);
      case 'SKV-2110':
        return configK10(form, personMeta);
      case 'SKV-2111':
        return configK11(form, personMeta);
      case 'SKV-2112':
        return configK12(form, personMeta);
      case 'SKV-2113':
        return configK13(form, personMeta);
      case 'SKV-2115':
        return configK15A(form, personMeta);
      case 'SKV-2116':
        return configK15B(form, personMeta);
      case 'SKV-2153':
        return configN3A(form, financialYearStart, financialYearEnd);
      case 'SKV-2051':
        return configT2(form, personMeta);
      case 'SKV-FRI':
        return configFritextbrev(
          form,
          personMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-OVR':
        return configOVR(
          form,
          personMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-CL':
        return configCoverLetter(
          form,
          personMeta,
          accountingFirm,
          financialYearStart
        );
      case 'SKV-2161':
        return configNEPerson(
          form,
          personMeta,
          financialYearStart,
          financialYearEnd
        );
      case 'SKV-2196':
        return configNEPersonHelper(
          form,
          personMeta,
          financialYearStart,
          financialYearEnd
        );
      default:
        throw new Error(`No config for private person ${form.type}`);
    }
  }

  throw new Error(
    `No config can be generated for customer, orgNumber and personNumber is missing.`
  );
};

export default getTaxDeclarationFormDataConfig;
