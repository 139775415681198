import React from 'react';
import {
  FlagValue,
  FlaggStore,
  flagg,
  localStore,
  useFeatureFlag as ffUseFeatureFlag,
} from 'flagg';
import { FlaggProvider } from 'flagg/dist/react';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';

//
// Please include Epic/Story issue number as description.
// Provide a comment if the feature flag should be released with others, etc.
//
// Add the property "backend" with the name of the backend feature flag that
// is connected to it.
//
const definitions = {
  // Next three flags should be removed together
  feature_cs_data: {
    // https://agoyit.atlassian.net/browse/AGOY-4413
    description:
      '[AGOY-4413] Possibility to populate various fields with data from CS in client settings.',
    default: false,
  },
  test_fortnoxLoginLocalhost: {
    // This is a internal feature flag. Do not remove.
    default: false,
    description:
      '[Development] Enables redirect from test to localhost for Fortnox login. Used for development only.',
  },
  feature_disconnectFortnox: {
    // Enables a feature/button to remove a organisation from Fortnox.
    // Maybe we should remove this flag and feature completely. Only support it manually.
    default: false,
    description:
      '[Support] Enable the disconnect from Fortnox for organisation that previously where origin=agoy.',
  },
  feature_economicAssociationSupport: {
    // https://agoyit.atlassian.net/browse/AGOY-4717
    // Enable after testing completes?
    default: false,
    description:
      '[AGOY-4717] Enable economic association (Ekonomisk förening) company annual report and references',
  },
  feature_limitedCompaniesSupport: {
    // https://agoyit.atlassian.net/browse/AGOY-4818
    // Enable after testing completes?
    default: false,
    description:
      '[AGOY-4818] Enable limited companies (Kommanditbolag or Handelsbolag) annual report and references',
  },
  feature_nonProfitOrgCompaniesSupport: {
    // https://agoyit.atlassian.net/browse/AGOY-4820
    // Enable after testing completes?
    default: false,
    description:
      '[AGOY-4820] Enable non profit association (Ideell förening) report and references',
  },
  feature_foundationCompanySupport: {
    // https://agoyit.atlassian.net/browse/AGOY-4819
    // Enable after testing completes?
    default: false,
    description:
      '[AGOY-4819] Enables support for foundation (Stiftelse) companies annual report and references',
  },
  feature_avstamningChecklistTemplate: {
    // Enabling a specifc Checklist template only for BDO
    // https://agoyit.atlassian.net/browse/AGOY-5053
    // Keep this feature flag until we have a proper solution.
    default: false,
    description:
      '[AGOY-5053] Enable new avstamning checklist template for BDO only.',
  },
  feature_noFinYearLimit: {
    // https://agoyit.atlassian.net/browse/AGOY-5284
    // This is for development. Keep it.
    default: false,
    description:
      '[Development/AGOY-5284] Remove the date limit for a financialYear in order to show a form in Skatt when the finYear is not finished yet. Used for development.',
  },
  feature_AnnualReportSharesVersion: {
    default: '1',
    options: ['1', '2'],
    description:
      '[AGOY-6973] Annual report version "2" for Aktiebolag. Default is "1". NOTE: Controlled by AWS Cloudwatch Evidently',
    backend: 'AnnualReportSharesVersion',
  },
  feature_newSpecifications: {
    default: false,
    description: '[AGOY-8622] BC24 Update specifications in the reconciliation',
  },
  feature_testPdfPrinting: {
    default: false,
    description: '[AGOY-7708] Add test route for testing pdf printing',
  },
  ...(import.meta.env.VITE_RUNNING_ENVIRONMENT === 'production' // Exclude flag in production
    ? {}
    : {
        devTools: {
          // Enabled by default in dev and off in test envs
          default: !import.meta.env.VITE_RUNNING_ENVIRONMENT,
          description: 'Enables Agoy Dev Tools in the UI',
        },
      }),
};

const isBackendFeature = (featureFlag: string): boolean =>
  typeof definitions[featureFlag]?.backend === 'string';

/**
 * Creates a FlaggStore with integration to the backend feature-flags
 *
 * @returns A custom implementation of a store backed by a standard "localStore"
 */
const backendFeatureFlags = (): FlaggStore => {
  // Standard store for local storage.
  const local = localStore();

  // The backend flags that already are being requested.
  const requested: Map<string, number> = new Map<string, number>();

  // When the feature flags are initialized, we may not have setup the
  // context for the user with tokens for backend communication.
  // This method checks the getContext until it returns a context.
  const pollForContext = (): Promise<ReturnType<typeof getContext>> => {
    let counter = 1000;
    return new Promise((resolve, reject) => {
      const poll = () => {
        try {
          resolve(getContext());
        } catch (error) {
          counter -= 1;
          if (counter < 1) {
            // Reject after 1000 attempts (50 seconds)
            reject(error);
          }
          // Retry
          setTimeout(poll, 50);
        }
      };
      poll();
    });
  };

  const isAlreadyRequested = (featureFlag: string): boolean => {
    const expireTime = requested.get(featureFlag);
    return expireTime ? expireTime > Date.now() : false;
  };

  return {
    name: 'Agoy Feature flags',
    get: (featureFlag) => {
      if (isBackendFeature(featureFlag) && !isAlreadyRequested(featureFlag)) {
        // No more requests for 10 seconds
        requested.set(featureFlag, Date.now() + 10000);

        const fetchFlag = async () => {
          try {
            const ffResult = await asResultClass(
              getApiSdk(await pollForContext()).getFeatureFlag({
                flag: definitions[featureFlag].backend,
              })
            );
            if (ffResult.ok) {
              local.set(featureFlag, ffResult.val);
            } else {
              // eslint-disable-next-line no-console
              console.warn('Failed to lookup feature flag');
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Feature flag error', error);
          }
        };
        fetchFlag();
      }
      return local.get(featureFlag);
    },
    set: (featureFlag, value) => {
      if (isBackendFeature(featureFlag)) {
        // eslint-disable-next-line no-console
        console.warn('Feature cannot be updated in FE');
      } else {
        local.set(featureFlag, value);
      }
    },
    all: () => {
      return local.all();
    },
    remove: (featureFlag) => {
      local.remove(featureFlag);
    },
  };
};

// https://github.com/jamesmfriedman/flagg#solutions-by-use-case
export const activeFeatureFlags = flagg<keyof typeof definitions>({
  store: backendFeatureFlags(),
  definitions,
});

/**
 * Exporting our own useFeatureFlag with typing
 */
export const useFeatureFlag = <T extends FlagValue>(
  flagName: keyof typeof definitions
) => ffUseFeatureFlag<T>(flagName);

const withFeatureFlags = (Component) => (props) =>
  (
    <FlaggProvider featureFlags={activeFeatureFlags}>
      <Component {...props} />
    </FlaggProvider>
  );

export default withFeatureFlags;
