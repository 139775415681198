import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import Expandable from './Expandable';
import ColumnItem, { Column } from './ColumnItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  padding: 24px 24px 24px 0;
`;

const Wrapper = styled.div`
  padding-left: 24px;
`;

const ActionButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  padding: ${({ theme }) => theme.spacing(3)}px;
  gap: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: ${({ theme }) => theme.shadows[4]};
  width: 100%;
`;

const mockedColumns: Column[] = [
  {
    id: 1,
    contentType: 'TEXT',
    endDate: null,
    name: 'Description',
    startDate: null,
    type: 'DEFAULT',
    checked: false,
    pinned: false,
  },
  {
    id: 2,
    contentType: 'AMOUNT',
    endDate: null,
    name: 'Total Cost',
    startDate: null,
    type: 'USER',
    checked: true,
    pinned: false,
    order: 1,
  },
  {
    id: 3,
    contentType: 'DATE',
    endDate: null,
    name: 'Start Date',
    startDate: null,
    type: 'DEFAULT',
    checked: false,
    pinned: false,
  },
  {
    id: 4,
    contentType: 'PERCENTAGE',
    endDate: null,
    name: 'Completion Rate',
    startDate: null,
    type: 'USER',
    checked: true,
    pinned: false,
    order: 2,
  },
  {
    id: 5,
    contentType: 'TEXT',
    endDate: null,
    name: 'Project Name',
    startDate: null,
    type: 'USER',
    checked: false,
    pinned: false,
  },
  {
    id: 6,
    contentType: 'DATE',
    endDate: null,
    name: 'End Date',
    startDate: null,
    type: 'DEFAULT',
    checked: true,
    pinned: false,
    order: 3,
  },
  {
    id: 7,
    contentType: 'AMOUNT',
    endDate: null,
    name: 'Discount',
    startDate: null,
    type: 'DEFAULT',
    checked: false,
    pinned: false,
  },
  {
    id: 8,
    contentType: 'TEXT',
    endDate: null,
    name: 'Notes',
    startDate: null,
    type: 'USER',
    checked: true,
    pinned: false,
    order: 4,
  },
  {
    id: 9,
    contentType: 'PERCENTAGE',
    endDate: null,
    name: 'Interest Rate',
    startDate: null,
    type: 'DEFAULT',
    checked: false,
    pinned: false,
  },
  {
    id: 10,
    contentType: 'TEXT',
    endDate: null,
    name: 'Category',
    startDate: null,
    type: 'USER',
    checked: true,
    pinned: false,
    order: 5,
  },
];

interface ColumnSelectorProps {
  onSave: () => void;
  onCancel: () => void;
}

const ColumnSelector = ({
  onSave,
  onCancel,
}: ColumnSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [columns, setColumns] = useState(mockedColumns);

  const selectedColumns = columns
    .filter((column) => column.checked)
    .sort((a, b) => (a.order || 0) - (b.order || 0));

  const handleCheck = (id: number, checked: boolean) => {
    setColumns((currentValue) => {
      const updated = [...currentValue];
      const index = updated.findIndex((col) => col.id === id);
      updated[index] = { ...updated[index], checked };
      return updated;
    });
  };

  const handlePin = (id: number) => {
    setColumns((currentValue) => {
      const updated = [...currentValue];
      const index = updated.findIndex((col) => col.id === id);
      updated[index] = { ...updated[index], pinned: !updated[index].pinned };
      return updated;
    });
  };

  const handleChangeOrder = (id: number, dropId: number) => {
    setColumns((currentValue) => {
      const updated = [...currentValue];

      const fromIndex = updated.findIndex((col) => col.id === id);
      const movedColumn = updated[fromIndex];
      updated.splice(fromIndex, 1);

      const toIndex = updated.findIndex((col) => col.id === dropId);
      updated.splice(toIndex, 0, movedColumn);

      return updated.map((col, index) => ({ ...col, order: index + 1 }));
    });
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Button
            label={formatMessage({ id: 'hidden.specification.createColumn' })}
            size="small"
            variant="outlined"
          />
        </Wrapper>

        <Expandable
          title={formatMessage({ id: 'hidden.specification.selectedColumns' })}
          defaultExpanded
        >
          {selectedColumns.map((column) => (
            <ColumnItem
              key={column.id}
              column={column}
              isDraggable
              onCheck={handleCheck}
              onPin={handlePin}
              onChangeOrder={handleChangeOrder}
            />
          ))}
        </Expandable>

        <Expandable
          title={formatMessage({ id: 'hidden.specification.assets' })}
        >
          {columns.map((column) => (
            <ColumnItem
              key={column.id}
              column={column}
              onCheck={handleCheck}
              onPin={handlePin}
              onChangeOrder={handleChangeOrder}
            />
          ))}
        </Expandable>
        <Expandable
          title={formatMessage({ id: 'hidden.specification.accruals' })}
        >
          {null}
        </Expandable>
      </Container>

      <ActionButtons>
        <Button
          label={formatMessage({ id: 'hidden.specification.save' })}
          size="medium"
          onClick={onSave}
        />
        <Button
          label={formatMessage({ id: 'hidden.button.cancel' })}
          size="medium"
          variant="outlined"
          onClick={onCancel}
        />
      </ActionButtons>
    </>
  );
};

export default ColumnSelector;
